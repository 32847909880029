<template>
  <div class="web-footer">
    <div class="web-footer-wrap">
      <div class="web-footer-wrap-title">
        智库体系
      </div>
      <div class="web-footer-wrap-list">
        <template v-for="(item, index) in friendList">
          <a :href="item.link" :target="item.target ? '_blank' : '_self'" class="web-footer-wrap-list-cell" :key="index" v-if="item.link">
            {{ item.name }}
          </a>
          <span class="web-footer-wrap-list-cell" :key="index" v-else>
            {{ item.name }}
          </span>
        </template>
      </div>
      <div class="web-footer-wrap-bottom">
        <div class="web-footer-wrap-bottom-left">
          <span>{{ info.copyright }}</span>
          <span v-if="info.beiAn">
            <a :href="info.beiAnUrl" target="_blank" v-if="info.beiAnUrl">{{ info.beiAn }}</a>
            <template v-else>
              {{ info.beiAn }}
            </template>
          </span>
          <span v-if="info.gonGan">
            <a :href="info.gonGanUrl" target="_blank" v-if="info.gonGanUrl">{{ info.gonGan }}</a>
            <template v-else>
              {{ info.gonGan }}
            </template>
          </span>
        </div>
        <div class="web-footer-wrap-bottom-right">
          <div class="web-footer-wrap-bottom-right-list">
            <a href="/contactUs#pagehire">
              招聘信息
            </a>
            <a href="/contactUs">
              联系我们
            </a>
          </div>
          <div class="web-footer-wrap-bottom-right-list">
            <div class="web-footer-wrap-bottom-right-list-icon" v-if="info.qrcode">
              <el-image :src="info.qrcode" :preview-src-list="[info.qrcode]"></el-image>
            </div>
            <div class="web-footer-wrap-bottom-right-list-icon" v-if="info.applet">
              <el-image :src="info.applet" :preview-src-list="[info.applet]"></el-image>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { friendLinks, configInfo } from "@/api";
export default {
  name: "WebFooter",
  data() {
    return {
      friendList: [],
      info: {
        copyright: "",
        beiAn: "",
        beiAnUrl: "",
        gonGan: "",
        gonGanUrl: "",
        qrcode: "",
        applet: ""
      }
    }
  },
  created() {
    this.getThinkTanks();
    this.getConfigInfo();
  },
  methods: {
    // 获取智库体系
    async getThinkTanks() {
      let res = await friendLinks(
          { pageSize: 100,
            currentPage: 1,
            orderBy: "sort",
            sortedBy: "desc",
            search: "link_type:2|status:1",
            searchFields: "link_type:=|status:="
          });
      if (res && res.code == 200) {
        this.friendList = res.data.list;
      }
    },
    // 获取配置信息
    async getConfigInfo() {
      let res = await configInfo({});
      if (res && res.code == 200 && res.data && res.data.length > 0) {
        for (let i = 0; i < res.data.length; i++) {
          let item = res.data[i];
          if (item.config_key == "BEIAN") {
            this.info.beiAn = item.config_value;
            continue;
          } else if (item.config_key == "BEIANURL") {
            this.info.beiAnUrl = item.config_value;
            continue;
          } else if (item.config_key == "GONGAN") {
            this.info.gonGan = item.config_value;
            continue;
          } else if (item.config_key == "GONGANURL") {
            this.info.gonGanUrl = item.config_value;
            continue;
          } else if (item.config_key == "BANQUANXINXI") {
            this.info.copyright = item.config_value;
            continue;
          } else if (item.config_key == "WEB_SITE_QRCODE") {
            if (item.config_value) {
              this.info.qrcode = JSON.parse(item.config_value).img;
            }
            continue;
          } else if (item.config_key == "WECHAT_MINI_PROGRAM_QRCODE") {
            if (item.config_value) {
              this.info.applet = JSON.parse(item.config_value).img;
            }
            continue;
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.web-footer {
  color: #0b0b0b;
  padding: 50px 5% 30px 5%;
  background-color: #f2f2f7;
  &-wrap {
    padding: 0 10%;
    &-title {
      font-size: 18px;
      font-family: "HarmonyOS_Bold";
      text-align: center;
    }
    &-list {
      padding: 30px 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      border-bottom: 1px solid #dfdfdf;
      &-cell {
        position: relative;
        font-size: 14px;
        font-family: "HarmonyOS_Regular";
        padding: 0 20px;
        &:hover {
          color: #ed6f33;
          font-family: "HarmonyOS_Bold";
        }
        &:after {
          content: '';
          position: absolute;
          top: 25%;
          left: 0;
          width: 2px;
          height: 50%;
          background-color: #595959;
        }
        &:first-child {
          &:after{
            display: none;
          }
        }
      }
    }
    &-bottom {
      padding-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &-left {
        font-size: 12px;
        color: #747474;
        span {
          margin-right: 20px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
      &-right {
        display: flex;
        &-list {
          display: flex;
          align-items: center;
          a {
            color: #747474;
            padding-right: 30px;
            font-size: 14px;
          }
          &:first-child {
            padding-right: 50px;
          }
          &-icon {
            max-width: 50px;
            &:last-child {
              margin-left: 30px;
            }
          }
        }
      }
    }
  }
}
/* 针对笔记本屏幕或者缩放屏幕 */
@media screen and (min-width: 1470px) and (max-width: 1600px) {
  .web-footer {
    &-wrap {
      &-bottom {
        &-right {
          &-list {
            a {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1200px) and (max-width: 1470px) {
  .web-footer {
    padding: 30px;
    &-wrap {
      padding: 0;
      &-bottom {
        flex-direction: column;
        &-right {
          margin-top: 30px;
          &-list {
            a {
              padding-right: 0;
              margin-right: 15px;
              font-size: 14px;
            }
            &:first-child {
              padding-right: 20px;
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .web-footer {
    padding: 30px;
    &-wrap {
      padding: 0;
      &-list {
        &-cell {
          font-size: 12px;
          padding: 0 15px;
        }
      }
      &-bottom {
        flex-direction: column;
        &-right {
          margin-top: 30px;
          &-list {
            a {
              padding-right: 0;
              margin-right: 15px;
              font-size: 14px;
            }
            &:first-child {
              padding-right: 20px;
            }
          }
        }
      }
    }
  }
}
/* 针对平板 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .web-footer {
    padding: 30px;
    &-wrap {
      padding: 0;
      &-bottom {
        flex-wrap: wrap;
        &-right {
          margin-top: 30px;
          &-list {
            a {
              padding-right: 0;
              margin-right: 15px;
              font-size: 14px;
            }
            &:first-child {
              padding-right: 20px;
            }
          }
        }
      }
    }
  }
}
/* 针对手机 */
@media screen and (max-width: 767px) {
  .web-footer {
    padding: 30px;
    &-wrap {
      padding: 0;
      &-list {
        padding: 20px 0;
        &-cell {
          padding: 0 15px 0 0;
          &:after {
            display: none;
          }
        }
      }
      &-bottom {
        display: block;
        &-left {
          span {
            white-space: nowrap;
          }
        }
        &-right {
          margin-top: 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          &-list {
            a {
              padding-right: 0;
              margin-right: 15px;
              font-size: 14px;
            }
            &:first-child {
              padding-right: 0px;
            }
          }
        }
      }
    }
  }
}
</style>