import Vue from 'vue'
import store from '@/store'
import VueRouter from 'vue-router'
import { setPageSeo } from "@/utils/tool";
Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: "Index",
            meta: {
                title: '首页'
            },
            component: () => import('@/views/index'),
        },
        {
            path: '/index',
            meta: {
                title: '首页'
            },
            component: () => import('@/views/index'),
        },
        {
            path: '/about',
            name: "About",
            meta: {
                title: '关于我们'
            },
            component: () => import('@/views/about/index'),
        },
        {
            path: '/singles/:id',
            name: "Singles",
            meta: {
                title: '单页'
            },
            component: () => import('@/views/singles/index'),
        },
        {
            path: '/article/:modeId/:id',
            name: "Article",
            meta: {
                title: '文章详情'
            },
            component: () => import('@/views/article/index'),
        },
        {
            path: '/activity',
            name: "Activitys",
            meta: {
                title: '研讨活动'
            },
            component: () => import('@/views/activitys/index'),
        },
        {
            path: '/achievement',
            name: "Achievement",
            meta: {
                title: '研究成果'
            },
            component: () => import('@/views/achievement/index'),
        },
        {
            path: '/member',
            name: "Member",
            meta: {
                title: '成员名录'
            },
            component: () => import('@/views/member/index'),
        },
        {
            path: '/person/:id',
            name: "Person",
            meta: {
                title: '人员详情'
            },
            component: () => import('@/views/person/index'),
        },
        {
            path: '/exchange',
            name: "Exchange",
            meta: {
                title: '国际交流'
            },
            component: () => import('@/views/exchange/index'),
        },
        {
            path: '/publication',
            name: "Publication",
            meta: {
                title: '出版物'
            },
            component: () => import('@/views/publication/index'),
        },
        {
            path: '/book/:id',
            name: "Book",
            meta: {
                title: '出版物详情'
            },
            component: () => import('@/views/book/index'),
        },
        {
            path: '/contactUs',
            name: "ContactUs",
            meta: {
                title: '联系我们'
            },
            component: () => import('@/views/contactUs/index'),
        },
        {
            path: '/search',
            name: "Searchs",
            meta: {
                title: '搜索页面'
            },
            component: () => import('@/views/searchs/index'),
        },
        {
            path: "*",
            meta: {
                title: '404'
            },
            component: () => import('@/views/errors/error404.vue'),
        },
        {
            path: "/stop",
            meta: {
                title: '暂停服务'
            },
            component: () => import('@/views/errors/errorStop.vue'),
        },
    ],
});

router.beforeEach((to, from, next) => {
    store.dispatch('getGlobalConfig').then(() => {
        if (store.getters.getMourningMod) {
            document.getElementsByTagName("html")[0].classList.toggle('condoles');
        }
        if (!store.getters.getWebOpenStatus) {
            next('/stop')
        }
        if (store.getters.getWebSiteTitle) {
            setPageSeo(store.getters.getWebSiteTitle,store.getters.getWebSiteKeywords,store.getters.getWebSiteDesprition);
        } else {
            setPageSeo("金融四十人论坛_Finance 40 Forum","Finance 40 Forum","金融四十人论坛_Finance 40 Forum");
        }
    });
    next();
});

export default router;