<template>
  <div class="activity" v-loading="loading" element-loading-text="拼命加载中...">
    <web-header active-url="/activity" borderBottom></web-header>
    <div class="activity-wrap">
      <div class="activity-wrap-header">
        <div class="activity-wrap-header-swiper">
          <swiper :options="swiperOption" class="activity-wrap-header-swiper-con" ref="swipeRef">
            <swiper-slide v-for="(item,index) in carouseList" :key="index">
              <img :src="item.pic.img" @click="jumpTopage('/article/1/'+item.id, item)" />
              <div class="swiper-desc">
                <div class="swiper-desc-tip">
                  {{ removeHtmlTags(item.introduction) }}
                </div>
                <div class="swiper-desc-time">{{ $moment(item.send_time).format("YYYY年MM月DD日") }}</div>
              </div>
            </swiper-slide>
            <div class="swiper-button-prev index-swiper-prev" slot="button-prev"></div>
            <div class="swiper-button-next index-swiper-next" slot="button-next"></div>
          </swiper>
        </div>
        <div class="activity-wrap-header-box">
          <div class="activity-wrap-header-box-list">
            <div :class="{ 'activity-wrap-header-box-list-cell': true, 'active': item.id == rightSelect.id}" v-for="(item, index) in rightMenu" :key="index" @mouseenter="openRightMenu(item)" @mouseleave="openRightMenu(false)">
              <div class="text">
                <span>{{ item.title }}</span>
                <i class="el-icon-arrow-right"></i>
              </div>
              <div :class="{ 'activity-wrap-header-box-list-cell-tier': true, 'one': rightSelect.id == 1, 'two': rightSelect.id == 2, 'three': rightSelect.id == 3, 'four': rightSelect.id == 4 }" v-if="item.id == rightSelect.id">
                <div class="activity-wrap-header-box-list-cell-tier-desc">
                  {{ rightSelect.desc }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="activity-wrap-list">
        <div class="activity-wrap-list-title">近期活动</div>
        <div class="activity-wrap-list-cell" v-for="(item, index) in dataList" :key="index">
          <div class="activity-wrap-list-cell-left">
            <a :href="createUrl('/article/1/'+item.id, item)" target="_blank" class="title">{{ item.title }}</a>
            <div class="desc">
              {{ removeHtmlTags(item.introduction) }}
            </div>
            <div class="tips">
              <div class="tag">{{ $moment(item.send_time).format("YYYY-MM-DD") }}</div>
              <div class="tag" v-if="item.organization_member_urls && item.organization_member_urls.length > 0">
                <template v-for="(jtem, jndex) in item.organization_member_urls">
                  <a :href="'/person/'+jtem.id" target="_blank" class="tag_link" :key="jndex" v-if="jtem.id">
                    {{ jtem.name }}
                  </a>
                  <span :key="jndex" v-else>
                  {{ jtem.name }}
                  </span>
                </template>
              </div>
              <template v-else>
                <div class="tag" v-if="item.author">{{ item.author }}</div>
              </template>
            </div>
          </div>
          <a :href="createUrl('/article/1/'+item.id, item)" target="_blank" class="activity-wrap-list-cell-right" v-if="item.cover_image && item.cover_image.img">
            <img :src="item.cover_image.img" />
          </a>
        </div>
        <el-empty description="暂无数据" v-if="!dataList || dataList.length == 0"></el-empty>
      </div>
      <div class="activity-wrap-pagination" v-if="page.total > page.pageSize">
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="page.current"
            :page-size="page.pageSize"
            layout="prev, pager, next"
            :total="page.total">
        </el-pagination>
      </div>
      <div class="activity-wrap-page">
        <el-button type="danger" size="medium" round @click="dataMore" v-if="!noneMore && page.total > page.pageSize">查看更多</el-button>
        <div class="none-more" v-else>没有更多了</div>
      </div>
    </div>
    <web-footer></web-footer>
    <web-right-menu></web-right-menu>
  </div>
</template>

<script>
import WebHeader from "@/components/webHeader";
import WebFooter from "@/components/webFooter";
import WebRightMenu from "@/components/webRightMenu";
import SwiperMixin from "./swiperMixin";
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
import { articleList } from "@/api";
export default {
  name: 'Activitys',
  mixins: [SwiperMixin],
  components: {
    WebHeader,
    WebFooter,
    WebRightMenu,
    Swiper,
    SwiperSlide
  },
  data () {
    return {
      // 轮播图
      carouseList: [],
      loading: false,
      // 列表数据
      dataList: [],
      page: {
        // 当前页
        current: 1,
        // 每页显示的条数
        pageSize: 10,
        orderBy: "send_time",
        sortedBy: "desc",
        // 总条数
        total: 0
      },
      noneMore: false,
      // 右侧展开菜单
      rightMenu: [],
      // 选中的右侧菜单ID
      rightSelect: {},
      isMobile: false
    }
  },
  created() {
    this.init();
  },
  mounted() {
    this.checkMobile();
    window.addEventListener('resize', this.checkMobile);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkMobile);
  },
  methods: {
    // 初始化数据
    init() {
      this.getParams();
      this.getCarousel();
      this.getDataList();
      this.rightMenu = [
        { id: 1, title: "CF40双周圆桌内部会", desc: "CF40双周闭门研讨会是四十人论坛定期举办的内部学术研讨会。与会嘉宾针对焦点和重大问题进行深度分析与辩论，选题角度侧重宏观经济和金融领域，讨论针锋相对，成果深入并富有建设性。研讨纪要通过CF40《要报》、《双周报》、《成果简报》报送相关决策部门领导及金融监管机构领导参考，并通过CF40网站、微信对外发布部分成果。" },
        { id: 2, title: "金融四十人年会", desc: "金融四十人年会是四十人论坛每年一届举行的重要会议，参加年会的除论坛全体成员外，还包括金融监管部门的主要领导、主要金融机构代表等。年会由论坛学术委员会根据当前经济金融形势和改革发展需求确定主题，并设有论坛成员内部议事会、年度优秀课题及图书颁奖等环节。" },
        { id: 3, title: "CF40宏观政策季度报告会", desc: "CF40宏观政策季度报告会由四十人论坛在每季度统计数据发布之后组织召开，就重点、热点宏观形势与政策问题进行专题报告与研讨。研讨会后召开媒体发布会，对报告进行公开发布。自2009年8月首期报告以来，CF40宏观政策季度报告会已举办61期，成为了CF40的品牌项目之一。" },
        { id: 4, title: "中国金融四十人青年论坛", desc: "“中国金融四十人青年论坛”是由中国金融四十人论坛发起并主办的学术平台，于2010年9月18日正式启动，并于2012年10月16日与当年6月25日发起的“中国金融四十人·金融街论坛”合并成为新的青年论坛。青年论坛的宗旨是为优秀青年经济学者提供学术与政策研究的交流平台；支持青年学者就宏观经济与金融领域的基础理论和重大政策问题开展研究，为学术研究提供基础，为政策部门提供决策参考。青年论坛施行会员制，活动形式包括双周内部研讨会、课题研究、工作论文与内部文章交流。" },
      ];
    },
    // 判断是否是手机端
    checkMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
    // 右侧菜单点击
    openRightMenu(e) {
      if (e && e.id) {
        if (e.id == this.rightSelect.id) {
          this.rightSelect = {};
        } else {
          this.rightSelect = e;
        }
      } else {
        this.rightSelect = {};
      }
    },
    // 获取轮播图数据
    async getCarousel() {
      let res = await articleList( 1,
          { pageSize: 20,
            currentPage: 1,
            orderBy: "send_time",
            sortedBy: "desc",
            search: "pic_id:0|class_id:12|topping:0|status:1",
            searchFields: "pic_id:>|class_id:=|topping:>|status:="
          });
      if (res && res.code == 200) {
        this.carouseList = res.data.list;
      }
    },
    // 获取参数
    getParams() {
      this.page.current = /^[1-9]\d*$/.test(this.$route.query.page) && Number(this.$route.query.page) > 0 ? Number(this.$route.query.page) : 1;
    },
    // 修改当前URL参数
    updateUrl() {
      // 获取当前URL主域名
      let url = window.location.origin;
      url = `${url}/activity?page=${this.page.current}`;
      history.replaceState(null, null, url);
    },
    // 去除HTML标签
    removeHtmlTags(value) {
      return value ? value.replace(/<[^>]+>/g, "").replace(/&nbsp;/g, '').replace(/^\s+|\s+$/g, '') : "";
    },
    // 获取数据
    async getDataList() {
      this.loading = true;
      let param = {
        pageSize: this.page.pageSize,
        currentPage: this.page.current,
        orderBy: this.page.orderBy,
        sortedBy: this.page.sortedBy,
        search: "class_id:12|status:1",
        searchFields: "class_id:=|status:="
      };
      let res = await articleList(1, param);
      if (res && res.code == 200) {
        if (this.isMobile) {
          this.dataList = this.dataList.concat(res.data.list);
        } else {
          this.dataList = res.data.list;
        }
        this.page.total = res.data.total;
      }
      this.updateUrl();
      this.loading = false;
    },
    // 分页，选中的分页
    handleCurrentChange(val) {
      this.page.current = val;
      this.getDataList();
    },
    // 创建URL链接
    createUrl(url, row) {
      if (row && row.jump_link) {
        return row.jump_link;
      }
      return url;
    },
    // 跳转链接
    jumpTopage(url, row) {
      if (row) {
        if (row.jump_link) {
          this.routerLink(row.jump_link, true);
        } else {
          this.routerLink(url, true);
        }
      }
    },
    // 加载更多
    dataMore() {
      if (this.page.total > this.page.current*this.page.pageSize) {
        this.page.current = this.page.current + 1;
        this.getDataList();
      } else {
        this.noneMore = true;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.activity {
  max-width: 1903px;
  margin: 0 auto;
  &-wrap {
    position: relative;
    width: 1000px;
    margin: 60px auto 50px auto;
    &-header {
      position: relative;
      margin: 0 auto;
      display: flex;
      &-swiper {
        width: 703px;
        &-con {
          max-width: 100%;
          img {
            cursor: pointer;
          }
          .swiper-slide {
            line-height: 0;
          }
          .swiper-desc {
            width: calc(100% - 40px);
            position: absolute;
            padding: 15px 20px 25px 20px;
            bottom: 0;
            left: 0;
            color: #ffffff;
            background: linear-gradient(to top, rgba(0, 0, 0, 0.9), transparent);
            &-title {
              width: 87%;
              cursor: pointer;
              font-size: 20px;
              line-height: 30px;
              font-family: HarmonyOS_Bold;
              display: inline-block;
              white-space: nowrap;
              overflow: hidden;
              text-overflow:ellipsis;
            }
            &-tip {
              width: 87%;
              margin-top: 10px;
              font-size: 14px;
              line-height: 22px;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            &-time {
              margin-top: 30px;
              font-size: 12px;
            }
          }
          .index-swiper-prev,.index-swiper-next {
            width: 10px;
            height: 10px;
            top: auto;
            color: #ffffff !important;
            &:after {
              font-size: 12px;
            }
          }
          .index-swiper-prev {
            right: 10%;
            left: auto;
            bottom: 20px;
          }
          .index-swiper-next {
            bottom: 20px;
            right: 5% !important;
          }
        }
      }
      &-box {
        width: calc(100% - 703px);
        &-list {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          &-cell {
            margin-top: 20px;
            flex: 1;
            .text {
              margin-left: 20px;
              height: 100%;
              cursor: pointer;
              user-select: none;
              display: flex;
              justify-content: space-between;
              padding: 0 3% 0 5%;
              align-items: center;
              background-color: #ffffff;
              border: 1px solid #e0e0e0;
              font-size: 18px;
              i {
                margin-left: 10px;
              }
            }
            &:first-child {
              margin-top: 0;
            }
            &-tier {
              position: absolute;
              top: 0;
              left: 0;
              width: 703px;
              height: 100%;
              border: 1px solid #e0e0e0;
              background-size: cover;
              background-position: center bottom;
              background-color: #f7f7f7;
              flex: 1;
              display: flex;
              align-items: center;
              z-index: 8;
              &.one {
                background-image: url("~@/assets/1.png");
              }
              &.two {
                background-image: url("~@/assets/2.png");
              }
              &.three {
                background-image: url("~@/assets/3.png");
              }
              &.four {
                background-image: url("~@/assets/4.png");
              }
              &-desc {
                margin: 35px;
                height: calc(100% - 70px);
                font-size: 16px;
                line-height: 32px;
                color: #1e1e1e;
                display: flex;
                align-items: center;
                overflow-y: scroll;
                scrollbar-width: none;
                scrollbar-color: transparent transparent;
                &::-webkit-scrollbar {
                  display: none;
                }
              }
            }
          }
          .active {
            .text {
              position: relative;
              margin-left: 1px;
              padding-left: 20px;
              border-left: 0;
              background-color: #f7f7f7;
              color: #1e1e1e;
              font-family: "HarmonyOS_Bold";
              z-index: 10;
              span {
                padding-left: 5%;
              }
            }
          }
        }
      }
    }
    &-list {
      width: 90%;
      margin: 80px auto 30px auto;
      &-title {
        font-size: 14px;
        color: #999999;
        margin-bottom: 30px;
      }
      &-cell {
        color: #999999;
        padding: 30px 0;
        border-top: 1px solid #dadada;
        display: flex;
        align-items: center;
        &-left {
          flex: 1;
          .title {
            color: #1e1e1e;
            font-size: 24px;
            font-family: "HarmonyOS_Bold";
            &:hover {
              color: #ed6f33;
            }
          }
          .desc {
            margin: 20px 0;
            font-size: 16px;
            line-height: 1.6;
            color: #999999;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .tips {
            font-size: 12px;
            display: flex;
            align-items: center;
            .tag {
              padding-right: 10px;
              a, span {
                margin-right: 5px;
                display: inline-block;
                &:last-child {
                  margin-right: 0;
                }
              }
              &_link {
                &:hover {
                  color: #ed6f33;
                }
              }
              &:after {
                content: '|';
                padding-left: 10px;
              }
              &:last-child {
                padding: 0;
                &:after {
                  content: "";
                }
              }
            }
          }
        }
        &-right {
          margin-left: 30px;
          max-width: 220px;
          line-height: 0;
          img {
            max-width: 220px;
          }
        }
        &:last-child {
          border-bottom: 1px solid #dadada;
        }
      }
    }
    &-pagination {
      text-align: center;
      margin: 10% 0 20% 0;
      ::v-deep {
        .el-pagination.is-background .el-pager li:not(.disabled):hover {
          color: #ed6f33;
        }
        .el-pagination.is-background .el-pager li:not(.disabled).active {
          background-color: #ed6f33;
        }
        .el-pagination.is-background .el-pager li:not(.disabled).active:hover {
          color: #ffffff;
        }
      }
    }
    &-page {
      display: none;
    }
  }
}
/* 针对笔记本屏幕或者缩放屏幕 */
@media screen and (min-width: 1360px) and (max-width: 1500px) {
  .activity {
    &-wrap {
      width: 60%;
      &-header {
        &-swiper {
          width: calc(100% - 300px);
        }
        &-box {
          width: 300px;
          &-list {
            &-cell {
              .text {
                font-size: 18px;
              }
              &-tier {
                width: calc(100% - 300px);
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1100px) and (max-width: 1360px) {
  .activity {
    &-wrap {
      width: 60%;
      &-header {
        &-swiper {
          width: calc(100% - 230px);
        }
        &-box {
          width: 230px;
          &-list {
            &-cell {
              .text {
                font-size: 14px;
              }
              &-tier {
                width: calc(100% - 230px);
                &-desc {
                  font-size: 14px;
                  line-height: 26px;
                }
              }
            }
          }
        }
      }
    }
  }
}
/* 针对平板 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .activity {
    &-wrap {
      width: 90%;
      &-header {
        &-swiper {
          width: calc(100% - 260px);
        }
        &-box {
          min-width: 260px;
          &-list {
            &-cell {
              .text {
                font-size: 14px;
              }
              &-tier {
                width: calc(100% - 260px);
                &-desc {
                  font-size: 14px;
                  line-height: 26px;
                }
              }
            }
          }
        }
      }
    }
  }
}
/* 针对手机 */
@media screen and (max-width: 767px) {
  .activity {
    &-wrap {
      width: 90%;
      margin-top: 30px;
      &-header {
        flex-direction: column;
        &-swiper {
          width: 100%;
          &-con {
            .swiper-desc {
              width: calc(100% - 40px);
              position: absolute;
              padding: 15px 20px 30px 20px;
              &-time {
                margin-top: 10px;
                font-size: 12px;
              }
            }
            .index-swiper-prev {
              right: 12%;
            }
            .index-swiper-prev,.index-swiper-next {
              bottom: 20px;
            }
          }
        }
        &-box {
          min-width: 100%;
          margin-left: 0;
          margin-top: 20px;
          &-list {
            &-cell {
              .text {
                padding: 20px 8px 20px 20px;
                margin-left: 0;
              }
              &-tier {
                position: static;
                border: none;
                width: 100%;
                height: auto;
              }
            }
            .active {
              border: 1px solid #e0e0e0;
              .text {
                margin-left: 0;
                border-top: none;
                border-right: none;
              }
            }
          }
        }
      }
      &-list {
        width: 100%;
        margin-top: 30px;
        &-cell {
          padding: 15px 0;
          flex-direction: column-reverse;
          align-items: flex-start;
          &-left {
            margin-top: 10px;
            padding-right: 0;
            .title {
              font-size: 16px;
            }
            .desc {
              margin-top: 10px;
              font-size: 12px;
            }
            .tips {
              font-size: 12px;
              margin-top: 10px;
            }
          }
          &-right {
            max-width: 100%;
            margin-left: 0;
            img {
              max-width: 100%;
            }
          }
        }
      }
      &-pagination {
        display: none;
      }
      &-page {
        margin: 40px auto 30px auto;
        display: table;
        .none-more {
          font-size: 12px;
          padding: 5px 15px;
          border: 1px solid #DCDFE6;
        }
      }
    }
  }
}
</style>