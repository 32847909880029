<template>
  <div class="web-right-menu">
    <a class="web-right-menu-item" href="/" target="_blank">
      <i class="iconfont icon-home"></i>
    </a>
    <div class="web-right-menu-item" v-show="isShowTopBtn" @click="scrollToTop">
      <i class="iconfont icon-to-top"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: "WebRightMenu",
  data() {
    return {
      isShowTopBtn: false
    }
  },
  mounted() {
    // 页面加载完成后添加滚动监听
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    // 页面销毁前移除滚动监听
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      // 当页面滚动超过一定高度时显示按钮
      this.isShowTopBtn = window.pageYOffset > 400;
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }
}
</script>
<style lang="scss" scoped>
.web-right-menu {
  position: fixed;
  top: 35%;
  right: 20px;
  z-index: 99999999;
  &-item {
    width: 50px;
    height: 50px;
    background-color: #f7f7f7;
    border: 1px solid #ebebeb;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      font-size: 24px;
    }
    &:last-child {
      margin-top: 10px;
    }
    &:active, &:hover {
      color: #ffffff;
      border-color: #ed6f33;
      background-color: #ed6f33;
    }
  }
}
/* 针对平板 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .web-right-menu {
    position: fixed;
    top: 35%;
    right: 20px;
    &-item {
      width: 40px;
      height: 40px;
      i {
        font-size: 20px;
      }
    }
  }
}
/* 针对手机 */
@media screen and (max-width: 767px) {
  .web-right-menu {
    position: fixed;
    top: 35%;
    right: 20px;
    display: none;
    &-item {
      width: 30px;
      height: 30px;
      i {
        font-size: 18px;
      }
    }
  }
}
</style>