<template>
  <div class="article" v-loading="loading">
    <web-header borderBottom></web-header>
    <div class="article-card" id="printContent">
      <div class="article-card-title">
        {{ info.title }}
      </div>
      <div class="article-card-opera" :style="{ padding: info.pdf_url_id ? '0' : '15px 0' }">
        <div class="article-card-opera-text">
          <div class="tag" v-if="info.organization_member_urls && info.organization_member_urls.length > 0">
            <template v-for="(jtem, jndex) in info.organization_member_urls">
              <a :href="'/person/'+jtem.id" target="_blank" :key="jndex" v-if="jtem.id">
                {{ jtem.name }}
              </a>
              <span :key="jndex" v-else>
                {{ jtem.name }}
              </span>
            </template>
          </div>
          <div class="tag" v-else-if="info.author">{{ info.author }}</div>
          <div class="tag">{{ $moment(info.send_time).format("YYYY-MM-DD") }}</div>
        </div>
        <div class="article-card-opera-btn">
          <a :href="info.pdf_url.img" target="_blank" class="article-card-opera-btn-download" v-if="info.pdf_url_id && info.pdf_url && info.pdf_url.img">
            <i class="iconfont icon-downloadPDF"></i>
            <span>Download PDF</span>
          </a>
          <div class="article-card-opera-btn-oa">
            <div class="article-card-opera-btn-oa-lump" @click="printFn">
              <i class="iconfont icon-print"></i>
            </div>
            <div class="article-card-opera-btn-oa-lump" @click="copyUrl">
              <i class="iconfont icon-share"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="article-card-file" v-if="info.file && info.file.img">
        <div class="article-card-file-name">
          音频：{{ info.file_name }}
        </div>
        <audio controls="" controlslist="nodownload" :src="info.file.img"></audio>
      </div>
      <div class="article-card-content" v-html="info.content"></div>
      <div class="article-card-next" v-if="nextInfo.title && nextInfo.id">
        <div class="article-card-next-title">下一篇</div>
        <a :href="nextInfo.jump_link ? nextInfo.jump_link : '/article/1/'+nextInfo.id">{{ nextInfo.title }}</a>
      </div>
    </div>
    <web-footer></web-footer>
    <web-right-menu></web-right-menu>
  </div>
</template>

<script>
import WebHeader from "@/components/webHeader";
import WebFooter from "@/components/webFooter";
import WebRightMenu from "@/components/webRightMenu";
import print from 'print-js'
import { commonDetail, nextArticle } from "@/api";
export default {
  name: 'Article',
  components: {
    WebHeader,
    WebFooter,
    WebRightMenu,
  },
  data () {
    return {
      loading: false,
      info: {},
      nextInfo: {}
    }
  },
  created() {
    if (this.$route.params.modeId && this.isNumber(this.$route.params.modeId) && this.$route.params.id && this.isNumber(this.$route.params.id)) {
      this.getDataDetail(this.$route.params.modeId, this.$route.params.id);
    }
  },
  methods: {
    // 获取详情
    async getDataDetail(mode_id, msg_id) {
      this.loading = true;
      let res = await commonDetail(mode_id, msg_id);
      if (res && res.code == 200) {
        this.info = res.data;
        this.getNext(msg_id, mode_id, "send_time", "desc");
      }
      this.loading = false;
    },
    // 打印当前页
    printFn() {
      setTimeout(function () {
        print({
          printable: 'printContent',
          type: 'html',
          scanStyles: false,
          targetStyles: ['*']
        })
      }, 500);
    },
    // 复制分享
    async copyUrl() {
      const textarea = document.createElement('textarea');
      textarea.value = window.location.href;
      document.body.appendChild(textarea);
      textarea.select();
      textarea.setSelectionRange(0, 9999); // 为了兼容移动设备
      try {
        document.execCommand('copy');
        this.$message.success('当前地址（URL）已复制到剪贴板')
      } catch (err) {
        this.$message.error("当前地址（URL）复制失败，请手动复制分享")
      }
      document.body.removeChild(textarea);
    },
    // 获取下一篇
    async getNext(msg_id, model_id, order_by, sorted_by) {
      let res = await nextArticle({
        id: Number(msg_id),
        model_id: model_id,
        order_by: order_by,
        sorted_by: sorted_by
      });
      if (res && res.code == 200) {
        this.nextInfo = res.data;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.article {
  max-width: 1903px;
  margin: 0 auto;
  &-card {
    width: 50%;
    min-height: 500px;
    margin: 50px auto 80px auto;
    &-title {
      padding: 30px 40px 45px 0;
      font-size: 44px;
      font-family: "HarmonyOS_Bold";
    }
    &-opera {
      padding: 15px 0;
      border-top: 1px solid #dadada;
      border-bottom: 1px solid #dadada;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &-text {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #747474;
        .tag {
          span, a {
            margin-right: 10px;
            &:last-child {
              margin-right: 0;
            }
          }
          a {
            &:hover {
              color: #ed6f33;
            }
          }
          &:after {
            content: '|';
            padding: 0 10px;
          }
          &:last-child {
            &:after {
              display: none;
            }
          }
        }
      }
      &-btn {
        display: flex;
        &-download {
          cursor: pointer;
          user-select: none;
          padding: 15px 15px;
          border-left: 1px solid #dedede;
          border-right: 1px solid #dedede;
          color: #ed6f33;
          display: flex;
          align-items: center;
          background-color: #f7f7f7;
          i {
            font-size: 28px;
            margin-right: 10px;
          }
        }
        &-oa {
          min-width: 130px;
          display: flex;
          justify-content: center;
          align-items: center;
          &-lump {
            width: 30px;
            height: 30px;
            margin-right: 10px;
            color: #0b0b0b;
            cursor: pointer;
            background-color: #f7f7f7;
            border: 1px solid #ebebeb;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            &:hover, &.active {
              color: #fff;
              background-color: #ed6f33;
            }
          }
        }
      }
    }
    &-file {
      margin-top: 30px;
      border: 1px solid #EBEBEB;
      height: 45px;
      margin-bottom: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      overflow: hidden;
      &-name {
        font-size: 16px;
        display: inline-block;
        margin-left: 50px;
        position: relative;
      }
      audio {
        width: 250px;
        position: relative;
        margin-right: 50px;
        border: 0;
        outline: none;
      }
    }
    &-content {
      margin-top: 50px;
      min-height: 500px;
      ::v-deep {
        p {
          font-size: 16px;
          line-height: 32px;
          text-align: justify;
          margin-bottom: 18px;
        }
        video, img {
          max-width: 100% !important;
        }
        ol {
          margin-left: 15px;
          li {
            list-style: auto;
            word-break: break-all;
          }
        }
        ul {
          margin-left: 18px;
          li {
            list-style: disc;
            word-break: break-all;
          }
        }
        blockquote {
          margin: 10px 0;
          padding: 10px;
          background-color: #f5f2f0;
          border-left: 8px solid #ed6f33;
        }
        code {
          background-color: #f5f2f0;
        }
        hr {
          margin: 20px;
          background-color: #ccc;
          height: 1px;
          display: block;
          border: 0;
        }
      }
    }
    &-next {
      margin-top: 100px;
      &-title {
        font-size: 12px;
      }
      a {
        width: 95%;
        margin-top: 15px;
        font-size: 32px;
        font-family: "HarmonyOS_Bold";
        display: inline-block;
        &:hover {
          color: #ed6f33;
        }
      }
    }
  }
}
/* 针对笔记本屏幕或者缩放屏幕 */
@media screen and (min-width: 1024px) and (max-width: 1600px) {
  .article {
    &-card {
      width: 70%;
    }
  }
}
/* 针对平板 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .article {
    &-card {
      width: 90%;
      min-height: 300px;
      margin: 20px auto 40px auto;
      &-title {
        padding: 15px 20px 15px 0;
        font-size: 30px;
      }
      &-content {
        min-height: 300px;
      }
      &-next {
        margin-top: 50px;
        a {
          font-size: 30px;
        }
      }
    }
  }
}
/* 针对手机 */
@media screen and (max-width: 767px) {
  .article {
    &-card {
      width: calc(100% - 30px);
      min-height: 300px;
      margin: 10px auto 20px auto;
      &-title {
        padding: 15px 20px 15px 0;
        font-size: 22px;
      }
      &-opera {
        &-text {
          span {
            font-size: 12px;
            &:after {
              padding: 0 5px;
            }
          }
        }
        &-btn {
          &-download {
            font-size: 12px;
            padding: 0px 5px;
            i {
              font-size: 22px;
              margin-right: 5px;
            }
          }
          &-oa {
            display: none;
          }
        }
      }
      &-content {
        min-height: 300px;
        margin-top: 15px;
      }
      &-next {
        margin-top: 50px;
        a {
          font-size: 22px;
        }
      }
    }
  }
}
</style>