<template>
  <div class="publication">
    <web-header active-url="/publication" borderBottom></web-header>
    <div class="publication-wrap">
      <div class="publication-wrap-book" v-loading="bookLoading">
        <div class="publication-wrap-book-header">
          <div class="publication-wrap-book-header-title">出版物</div>
          <div class="publication-wrap-book-header-tab">
            <div v-for="(item, index) in bootTypeList" :class="{ 'publication-wrap-book-header-tab-cell': true, 'active': selectBookType == item.dict_value }" @mouseover="changeBookType(item.dict_value, index)" :key="index">
              {{ item.dict_label }}
            </div>
          </div>
        </div>
        <swiper :options="publicationSwiperOption" class="publication-wrap-book-swiper swiper-no-swiping" ref="publicationSwiperRef">
          <swiper-slide v-for="(bookType, bookIndex) in bootTypeList" :key="bookIndex">
            <div class="publication-wrap-book-list">
              <div class="publication-wrap-book-list-cell" v-for="(item, index) in bookList" :key="index">
                <a :href="selectBookType == '99' ? createUrl('/article/1/'+item.id, item) : createUrl('/book/'+item.id, item)" target="_blank" class="icon" v-if="(item.litpic && item.litpic.img) || (item.cover_image && item.cover_image.img)">
                  <img :src="item.litpic.img" v-if="item.litpic && item.litpic.img" />
                  <img :src="item.cover_image.img" v-else-if="item.cover_image && item.cover_image.img" />
                </a>
                <a :href="selectBookType == '99' ? createUrl('/article/1/'+item.id, item) : createUrl('/book/'+item.id, item)" target="_blank" class="title">{{ item.name || item.title }}</a>
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <el-empty description="暂无数据" v-if="!bookList || bookList.length == 0"></el-empty>
        <div class="paging" v-if="bookList && bookList.length > 0">
          <el-divider>
            <div class="page-icon">
              <div :class="{ 'prev': true, 'none': bookPage.current == 1 }" @click="prevBook">
                <div class="prev-icon"></div>
              </div>
              <div :class="{ 'next': true, 'none': bookPage.total <= bookPage.current * bookPage.pageSize }" @click="nextBook">
                <div class="next-icon"></div>
              </div>
            </div>
          </el-divider>
        </div>
      </div>
      <div class="publication-wrap-video" v-loading="videoLoading" v-if="videoList && videoList.length > 0">
        <div class="publication-wrap-video-title">音视频节目</div>
        <div class="publication-wrap-video-list">
          <div class="publication-wrap-video-list-cell" v-for="(item, index) in videoList" :key="index">
            <a :href="createUrl('/article/1/'+item.id, item)" target="_blank" class="icon">
              <img :src="item.cover_image.img" />
              <div class="play" v-if="item.file_id">
                <i class="el-icon-caret-right"></i>
              </div>
            </a>
            <a :href="createUrl('/article/1/'+item.id, item)" target="_blank" class="title">{{ item.title }}</a>
          </div>
        </div>
        <el-empty description="暂无数据" v-if="!videoList || videoList.length == 0"></el-empty>
        <div class="paging" v-if="videoList && videoList.length > 0">
          <el-divider>
            <div class="page-icon">
              <div :class="{ 'prev': true, 'none': videoPage.current == 1 }" @click="prevVideo">
                <div class="prev-icon"></div>
              </div>
              <div :class="{ 'next': true, 'none': videoPage.total <= videoPage.current * videoPage.pageSize }" @click="nextVideo">
                <div class="next-icon"></div>
              </div>
            </div>
          </el-divider>
        </div>
      </div>
      <div class="publication-wrap-comment" v-loading="commentLoading" v-if="commentList && commentList.length > 0">
        <div class="publication-wrap-comment-title">新金融评论</div>
        <div class="publication-wrap-comment-list">
          <div class="publication-wrap-comment-list-cell" v-for="(item, index) in commentList" :key="index">
            <a :href="createUrl('/article/1/'+item.id, item)" target="_blank" class="icon">
              <img :src="item.cover_image.img" />
            </a>
            <a :href="createUrl('/article/1/'+item.id, item)" target="_blank" class="title">
              {{ item.title }}
            </a>
          </div>
        </div>
        <el-empty description="暂无数据" v-if="!commentList || commentList.length == 0"></el-empty>
        <div class="paging" v-if="commentList && commentList.length > 0">
          <el-divider>
            <div class="page-icon">
              <div :class="{ 'prev': true, 'none': commentPage.current == 1 }" @click="prevComment">
                <div class="prev-icon"></div>
              </div>
              <div :class="{ 'next': true, 'none': commentPage.total <= commentPage.current * commentPage.pageSize }" @click="nextComment">
                <div class="next-icon"></div>
              </div>
            </div>
          </el-divider>
        </div>
      </div>
    </div>
    <web-footer></web-footer>
    <web-right-menu></web-right-menu>
  </div>
</template>

<script>
import WebHeader from "@/components/webHeader";
import WebFooter from "@/components/webFooter";
import WebRightMenu from "@/components/webRightMenu";
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
import { dictionaryList, articleList } from "@/api";
export default {
  name: "Publication",
  components: {
    WebHeader,
    WebFooter,
    WebRightMenu,
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      // 书籍类型列表
      bootTypeList: [],
      // 选中的书籍类型
      selectBookType: "",
      // 书籍列表数据
      bookList: [],
      bookLoading: false,
      // 书籍分页
      bookPage: {
        // 当前页
        current: 1,
        // 每页显示的条数
        pageSize: 10,
        orderBy: "send_time",
        sortedBy: "desc",
        // 总条数
        total: 0
      },
      // 轮播图
      publicationSwiperOption: {
        autoplay: false,//可选选项，自动滑动
        loop: false, // 循环模式选项
      },
      // 音视频节目列表数据
      videoList: [],
      // 音视频节目加载
      videoLoading: false,
      // 音视频分页
      videoPage: {
        // 当前页
        current: 1,
        // 每页显示的条数
        pageSize: 10,
        orderBy: "send_time",
        sortedBy: "desc",
        // 总条数
        total: 0
      },
      // 新金融评论列表数据
      commentList: [],
      // 新金融评论加载
      commentLoading: false,
      // 新金融评论分页
      commentPage: {
        // 当前页
        current: 1,
        // 每页显示的条数
        pageSize: 4,
        orderBy: "send_time",
        sortedBy: "desc",
        // 总条数
        total: 0
      }
    }
  },
  created() {
    this.inits();
  },
  methods: {
    // 初始化
    inits() {
      this.getParams();
      this.getDictClass();
      this.getVideoList();
      this.getCommentList();
      if (this.selectBookType == '99') {
        this.getEnglishBookList();
      } else {
        this.getBookList();
      }
    },
    // 获取出版物书籍分类
    async getDictClass() {
      let param = {
        orderBy: "dict_sort",
        sortedBy: "desc",
        search: "dict_type:sys_book_type|dict_sort:0",
        searchFields: "dict_type:=|dict_sort:<>"
      };
      let res = await dictionaryList(param);
      if (res && res.code == 200) {
        this.bootTypeList = res.data.list;
        this.bootTypeList.push({
          dict_label: "英文书籍",
          dict_value: "99"
        });
        if (!this.selectBookType) {
          this.selectBookType = this.bootTypeList[0].dict_value;
        }
      }
    },
    // 获取书籍列表
    async getBookList() {
      let param = {
        pageSize: this.bookPage.pageSize,
        currentPage: this.bookPage.current,
        orderBy: this.bookPage.orderBy,
        sortedBy: this.bookPage.sortedBy,
        search: "litpic_id:0|class_id:25|status:1",
        searchFields: "litpic_id:>|class_id:=|status:="
      };
      this.bookLoading = true;
      if (this.selectBookType) {
        param.search = `${param.search}|book_type:${this.selectBookType}`;
        param.searchFields = `${param.searchFields}|book_type=`;
      }
      let res = await articleList(5, param);
      if (res && res.code == 200) {
        this.bookList = res.data && res.data.list && res.data.list.length > 0 ? res.data.list : [];
        this.bookPage.total = res.data.total;
        this.$nextTick(() => {
          if (this.$refs.publicationSwiperRef) {
            this.$refs.publicationSwiperRef.initSwiper();
          }
        });
      }
      this.updateUrl();
      this.bookLoading = false;
    },
    // 获取英文书籍
    async getEnglishBookList() {
      let param = {
        pageSize: this.bookPage.pageSize,
        currentPage: this.bookPage.current,
        orderBy: this.bookPage.orderBy,
        sortedBy: this.bookPage.sortedBy,
        search: "cover_image_id:0|class_id:33|status:1",
        searchFields: "cover_image_id:>|class_id:=|status:="
      };
      this.bookLoading = true;
      let res = await articleList(1, param);
      if (res && res.code == 200) {
        this.bookList = res.data && res.data.list && res.data.list.length > 0 ? res.data.list : [];
        this.bookPage.total = res.data.total;
        this.$nextTick(() => {
          if (this.$refs.publicationSwiperRef) {
            this.$refs.publicationSwiperRef.initSwiper();
          }
        });
      }
      this.updateUrl();
      this.bookLoading = false;
    },
    // 选择书籍分类
    async changeBookType(val, index) {
      if (this.selectBookType != val) {
        this.bookPage.current = 1;
        this.$refs.publicationSwiperRef.$swiper.slideTo(index);
        this.selectBookType = val;
        if (val == "99") {
          this.getEnglishBookList();
        } else {
          await this.getBookList();
        }
      }
    },
    // 书籍上一页
    prevBook() {
      this.bookPage.current = this.bookPage.current - 1;
      if (this.selectBookType == "99") {
        this.getEnglishBookList();
      } else {
        this.getBookList();
      }
    },
    // 书籍下一页
    nextBook() {
      this.bookPage.current = this.bookPage.current + 1;
      if (this.selectBookType == "99") {
        this.getEnglishBookList();
      } else {
        this.getBookList();
      }
    },
    // 获取参数
    getParams() {
      this.bookPage.current = /^[1-9]\d*$/.test(this.$route.query.bpage) && Number(this.$route.query.bpage) > 0 ? Number(this.$route.query.bpage) : 1;
      this.videoPage.current = /^[1-9]\d*$/.test(this.$route.query.vpage) && Number(this.$route.query.vpage) > 0 ? Number(this.$route.query.vpage) : 1;
      this.commentPage.current = /^[1-9]\d*$/.test(this.$route.query.cpage) && Number(this.$route.query.cpage) > 0 ? Number(this.$route.query.cpage) : 1;
      this.selectBookType = /^[1-9]\d*$/.test(this.$route.query.btype) && Number(this.$route.query.btype) > 0 ? Number(this.$route.query.btype) : "";
    },
    // 修改当前URL参数
    updateUrl() {
      // 获取当前URL主域名
      let url = window.location.origin;
      if (this.selectBookType) {
        url = `${url}/publication?bpage=${this.bookPage.current}&vpage=${this.videoPage.current}&cpage=${this.commentPage.current}&btype=${this.selectBookType}`;
      } else {
        url = `${url}/publication?bpage=${this.bookPage.current}&vpage=${this.videoPage.current}&cpage=${this.commentPage.current}`;
      }
      history.replaceState(null, null, url);
    },
    // 创建URL链接
    createUrl(url, row) {
      if (row && row.jump_link) {
        return row.jump_link;
      }
      return url;
    },
    // 获取音视频节目
    async getVideoList() {
      let param = {
        pageSize: this.videoPage.pageSize,
        currentPage: this.videoPage.current,
        orderBy: this.videoPage.orderBy,
        sortedBy: this.videoPage.sortedBy,
        search: "cover_image_id:0|class_id:26|status:1",
        searchFields: "cover_image_id:>|class_id:=|status:="
      };
      this.videoLoading = true;
      let res = await articleList(1, param);
      if (res && res.code == 200) {
        this.videoList = res.data.list;
        this.videoPage.total = res.data.total;
      }
      this.updateUrl();
      this.videoLoading = false;
    },
    // 音视频上一页
    prevVideo() {
      this.videoPage.current = this.videoPage.current - 1;
      this.getVideoList();
    },
    // 音视频下一页
    nextVideo() {
      this.videoPage.current = this.videoPage.current + 1;
      this.getVideoList();
    },
    // 获取新金融评论
    async getCommentList() {
      let param = {
        pageSize: this.commentPage.pageSize,
        currentPage: this.commentPage.current,
        orderBy: this.commentPage.orderBy,
        sortedBy: this.commentPage.sortedBy,
        search: "cover_image_id:0|class_id:27|status:1",
        searchFields: "cover_image_id:>|class_id:=|status:="
      };
      this.commentLoading = true;
      let res = await articleList(1, param);
      if (res && res.code == 200) {
        this.commentList = res.data.list;
        this.commentPage.total = res.data.total;
      }
      this.updateUrl();
      this.commentLoading = false;
    },
    // 音视频上一页
    prevComment() {
      this.commentPage.current = this.commentPage.current - 1;
      this.getCommentList();
    },
    // 音视频下一页
    nextComment() {
      this.commentPage.current = this.commentPage.current + 1;
      this.getCommentList();
    },
  }
}
</script>

<style lang="scss" scoped>
.publication {
  max-width: 1903px;
  margin: 0 auto;
  &-wrap {
    width: 60%;
    margin: 50px auto;
    ::v-deep {
      .el-divider__text {
        padding: 0;
      }
    }
    &-book {
      &-header {
        color: #1e1e1e;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &-title {
          font-size: 30px;
          font-family: "HarmonyOS_Bold";
        }
        &-tab {
          display: flex;
          &-cell {
            position: relative;
            cursor: pointer;
            user-select: none;
            color: #999999;
            font-size: 18px;
            margin-right: 30px;
            &:hover, &.active {
              color: #1e1e1e;
              &:after {
                width: 80%;
                content: " ";
                position: absolute;
                left: 10%;
                bottom: -10px;
                border-bottom: 2px solid #ed6f33;
              }
            }
          }
        }
      }
      &-list {
        margin-top: 30px;
        display: flex;
        flex-wrap: wrap;
        &-cell {
          width: 18%;
          margin-right: 2.5%;
          margin-bottom: 30px;
          .icon {
            line-height: 0;
            display: block;
            transform: scale(0.8);
            transition: transform 0.3s ease;
          }
          .title {
            text-align: center;
            margin-top: 10px;
            display: block;
          }
          &:hover {
            .icon {
              transform: scale(1);
            }
            .title {
              font-family: HarmonyOS_Bold;
              color: #ed6f33;
            }
          }
          &:nth-child(5n) {
            margin-right: 0;
          }
        }
      }
    }
    &-video {
      padding-top: 15px;
      &-title {
        font-size: 30px;
        font-family: "HarmonyOS_Bold";
      }
      &-list {
        margin-top: 30px;
        display: flex;
        flex-wrap: wrap;
        &-cell {
          width: 18%;
          max-width: 367px;
          margin-right: calc(10% / 4);
          margin-bottom: 40px;
          &:nth-child(5n) {
            margin-right: 0;
          }
          .icon {
            position: relative;
            line-height: 0;
            display: inline-block;
            .play {
              position: absolute;
              width: 40px;
              height: 40px;
              left: 0;
              bottom: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #ffffff;
              background-color: #ed6f33;
              i {
                font-size: 32px;
              }
            }
          }
          .title {
            margin-top: 10px;
            display: inline-block;
          }
          &:hover {
            .title {
              font-family: HarmonyOS_Bold;
              color: #ed6f33;
            }
          }
        }
      }
    }
    &-comment {
      padding: 15px 0 50px 0;
      &-title {
        font-size: 30px;
        font-family: "HarmonyOS_Bold";
      }
      &-list {
        margin-top: 30px;
        display: flex;
        &-cell {
          width: 23%;
          max-width: 282px;
          margin-right: calc(8% / 3);
          text-align: center;
          border: 1px solid #e3e3e3;
          display: flex;
          flex-direction: column;
          &:nth-child(4n) {
            margin-right: 0;
          }
          .icon {
            flex: 1;
            line-height: 0;
            display: block;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .title {
            padding: 10px 0;
            text-align: center;
            display: inline-block;
          }
          &:hover {
            .title {
              font-family: HarmonyOS_Bold;
              color: #ed6f33;
            }
          }
        }
      }
    }
    .paging {
      padding-top: 30px;
      .page-icon {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .next,.prev {
          width: 30px;
          height: 30px;
          line-height: 30px;
          cursor: pointer;
          user-select: none;
          border: 1px solid #ececec;
          color: #ed6f33;
          border-radius: 30px;
          display: flex;
          justify-content: center;
          &:hover {
            background-color: #ececec;
          }
          &.none {
            pointer-events: none;
            cursor: no-drop;
            &:hover {
              background-color: transparent;
            }
          }
        }
        .prev {
          margin-right: 30px;
        }
        .prev-icon {
          position: relative;
          &:after {
            font-size: 12px;
            color: #ed753c;
            content: 'prev';
            font-family: swiper-icons;
            text-transform: none !important;
            letter-spacing: 0;
            font-variant: initial;
            line-height: 1;
          }
        }
        .next-icon {
          position: relative;
          &:after {
            font-size: 12px;
            color: #ed753c;
            content: 'next';
            font-family: swiper-icons;
            text-transform: none !important;
            letter-spacing: 0;
            font-variant: initial;
            line-height: 1;
          }
        }
      }
    }
  }
}
/* 针对笔记本屏幕或者缩放屏幕 */
@media screen and (min-width: 1024px) and (max-width: 1600px) {
  .publication {
    &-wrap {
      width: 70%;
    }
  }
}
/* 针对平板 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .publication {
    &-wrap {
      width: 90%;
      &-comment {
        padding-bottom: 30px;
      }
    }
  }
}
/* 针对手机 */
@media screen and (max-width: 767px) {
  .publication {
    &-wrap {
      width: 90%;
      &-book {
        &-header {
          &-title {
            font-size: 18px;
          }
          &-tab {
            &-cell {
              font-size: 12px;
              margin-right: 8px;
            }
          }
        }
        &-list {
          &-cell {
            width: 48%;
            margin-right: 0;
            font-size: 12px;
            &:nth-child(2n) {
              margin-right: 4%;
            }
          }
        }
      }
      &-video {
        &-title {
          font-size: 18px;
        }
        &-list {
          &-cell {
            width: 48%;
            margin-right: 4%;
            margin-bottom: 20px;
            &:nth-child(2n) {
              margin-right: 0;
            }
            &:nth-child(5n) {
              margin-right: 4%;
            }
            &:nth-child(10n) {
              margin-right: 0;
            }
            .icon {
              .play {
                width: 30px;
                height: 30px;
                i {
                  font-size: 26px;
                }
              }
            }
          }
        }
      }
      &-comment {
        padding-bottom: 10px;
        &-title {
          font-size: 18px;
        }
        &-list {
          flex-wrap: wrap;
          &-cell {
            width: calc(48% - 2px);
            margin-right: 4%;
            font-size: 12px;
            margin-bottom: 20px;
            &:nth-child(2n) {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}
</style>