<template>
  <div class="achievement">
    <web-header active-url="/achievement" borderBottom></web-header>
    <div class="achievement-wrap" v-loading="loading" element-loading-text="拼命加载中...">
      <swiper :options="{autoplay: false, loop: false}" class="achievement-wrap-header-swiper swiper-no-swiping" ref="imgSwiperRef">
        <swiper-slide v-for="(item, index) in classifyList" :key="index">
          <div class="achievement-wrap-header" v-if="item.banner && item.banner.img">
            <img :src="item.banner.img" />
          </div>
        </swiper-slide>
      </swiper>
      <div class="achievement-wrap-list">
        <div class="achievement-wrap-list-tab">
          <div v-for="(item, index) in classifyList" :class="{ 'achievement-wrap-list-tab-item': true, 'active': selectClass == item.page_id }" @click="changeClass(item, index)" @mouseover="changeImg(index)" :key="index">
            {{ item.title }}
          </div>
        </div>
        <div class="achievement-wrap-list-content">
          <div class="achievement-wrap-list-content-cell" v-for="(item, index) in dataList" :key="index">
            <a :href="createUrl(item)" target="_blank" class="title">
              {{ selectClass == 9999 ? item.product_name : item.title }}
            </a>
            <div class="desc">
              {{ removeHtmlTags(selectClass == 9999 ? item.content : item.introduction) }}
            </div>
            <div class="tips">
              <div class="tag">{{ $moment(selectClass == 9999 ? item.publish_time : item.send_time).format("YYYY-MM-DD") }}</div>
              <div class="tag" v-if="item.product_class && item.product_class.class_name">{{ item.product_class.class_name }}</div>
              <div class="tag" v-if="item.organization_member_urls && item.organization_member_urls.length > 0">
                <template v-for="(jtem, jndex) in item.organization_member_urls">
                  <a :href="'/person/'+jtem.id" target="_blank" class="tag_link" :key="jndex" v-if="jtem.id">
                    {{ jtem.name }}
                  </a>
                  <span :key="jndex" v-else>
                  {{ jtem.name }}
                </span>
                </template>
              </div>
              <template v-else>
                <div class="tag" v-if="item.author">{{ item.author }}</div>
              </template>
            </div>
          </div>
        </div>
        <el-empty description="暂无数据" v-if="!dataList || dataList.length == 0"></el-empty>
      </div>
      <div class="achievement-wrap-pagination" v-if="page.total > page.pageSize">
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="page.current"
            :page-size="page.pageSize"
            layout="prev, pager, next"
            :total="page.total">
        </el-pagination>
      </div>
      <div class="achievement-wrap-page">
        <el-button type="danger" size="medium" round @click="dataMore" v-if="!noneMore && page.total > page.pageSize">查看更多</el-button>
        <div class="none-more" v-else>没有更多了</div>
      </div>
    </div>
    <web-footer></web-footer>
    <web-right-menu></web-right-menu>
  </div>
</template>

<script>
import WebHeader from "@/components/webHeader";
import WebFooter from "@/components/webFooter";
import WebRightMenu from "@/components/webRightMenu";
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
import { articleList, navigationList, reportList } from "@/api";
export default {
  name: 'Achievement',
  components: {
    WebHeader,
    WebFooter,
    WebRightMenu,
    Swiper,
    SwiperSlide
  },
  data () {
    return {
      // 加载状态
      loading: false,
      // 分类列表
      classifyList: [],
      // 默认分类
      defaultClass: 0,
      // 选中的分类
      selectClass: 0,
      // 列表数据
      dataList: [],
      page: {
        // 当前页
        current: 1,
        // 每页显示的条数
        pageSize: 10,
        orderBy: "send_time",
        sortedBy: "desc",
        // 总条数
        total: 0
      },
      noneMore: false,
      isMobile: false
    }
  },
  created() {
    this.init();
  },
  mounted() {
    this.checkMobile();
    window.addEventListener('resize', this.checkMobile);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkMobile);
  },
  methods: {
    // 初始化数据
    async init() {
      await this.getSubmenus();
      this.dataList = [];
      if (this.selectClass == 9999) {
        this.getReportList();
      } else {
        this.getDataList();
      }
    },
    // 判断是否是手机端
    checkMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
    // 创建URL链接
    createUrl(row) {
      if (row && row.jump_link) {
        return row.jump_link;
      } else {
        if (this.selectClass == 9999) {
          return 'https://www.cf40.com/report/chinese/'+row.id;
        } else {
          return `/article/1/${row.id}`;
        }
      }
    },
    // 获取参数
    getParams() {
      this.page.current = /^[1-9]\d*$/.test(this.$route.query.page) && Number(this.$route.query.page) > 0 ? Number(this.$route.query.page) : 1;
      this.selectClass = /^[1-9]\d*$/.test(this.$route.query.cid) && Number(this.$route.query.cid) > 0 ? Number(this.$route.query.cid) : this.defaultClass;
    },
    // 修改当前URL参数
    updateUrl() {
      // 获取当前URL主域名
      let url = window.location.origin;
      url = `${url}/achievement?page=${this.page.current}&cid=${this.selectClass}`;
      history.replaceState(null, null, url);
    },
    // 获取子菜单
    async getSubmenus() {
      let param = {
        pageSize: 1,
        currentPage: 1,
        orderBy: "sort",
        sortedBy: "asc",
        search: "link:/achievement",
        searchFields: "link:="
      };
      let res = await navigationList(param);
      if (res && res.code == 200) {
        if (res.data && res.data.list && res.data.list.length > 0) {
          this.submenusHandle(res.data.list[0]['child']);
        }
      }
    },
    // 菜单数据处理
    submenusHandle(rows) {
      if (rows && rows.length > 0) {
        rows.sort((a, b) => {
          return a.sort - b.sort;
        });
        this.classifyList = rows;
        this.defaultClass = rows[0].page_id;
        this.selectClass = rows[0].page_id;
        this.getParams();
      }
    },
    // 切换分类
    changeClass(e, index) {
      if (e && this.selectClass != e.page_id) {
        this.page.current = 1;
        this.$refs.imgSwiperRef.$swiper.slideTo(index);
        this.selectClass = e.page_id;
        this.dataList = [];
        if (this.selectClass == 9999) {
          this.getReportList();
        } else {
          this.getDataList();
        }
      }
    },
    // 切换轮播图
    changeImg(index) {
      this.$refs.imgSwiperRef.$swiper.slideTo(index);
    },
    // 获取数据
    async getDataList() {
      this.loading = true;
      let param = {
        pageSize: this.page.pageSize,
        currentPage: this.page.current,
        orderBy: this.page.orderBy,
        sortedBy: this.page.sortedBy,
        search: `class_id:${this.selectClass}`,
        searchFields: "class_id:="
      };
      let res = await articleList(1, param);
      if (res && res.code == 200) {
        if (this.isMobile) {
          this.dataList = this.dataList.concat(res.data.list);
        } else {
          this.dataList = res.data.list;
        }
        this.page.total = res.data.total;
      }
      this.updateUrl();
      this.loading = false;
    },
    // 获取CF40研究数据列表
    async getReportList() {
      this.loading = true;
      let param = {
        pageSize: this.page.pageSize,
        currentPage: this.page.current,
        search: "product_type:1|pdf_url_id:0|product_class_id:16,17,18",
        searchFields: "product_type:=|pdf_url_id:>|product_class_id:in",
        orderBy: "publish_time",
        sortedBy: "desc"
      };
      let res = await reportList(param);
      if (res && res.code == 200) {
        if (this.isMobile) {
          this.dataList = this.dataList.concat(res.data.list);
        } else {
          this.dataList = res.data.list;
        }
        this.page.total = res.data.total;
      }
      this.updateUrl();
      this.loading = false;
    },
    // 去除HTML标签
    removeHtmlTags(value) {
      return value ? value.replace(/<[^>]+>/g, "").replace(/&nbsp;/g, '').replace(/^\s+|\s+$/g, '') : "";;
    },
    // 分页，选中的分页
    handleCurrentChange(val) {
      this.page.current = val;
      if (this.selectClass == 9999) {
        this.getReportList();
      } else {
        this.getDataList();
      }
    },
    // 加载更多
    dataMore() {
      if (this.page.total > this.page.current*this.page.pageSize) {
        this.page.current = this.page.current + 1;
        if (this.selectClass == 9999) {
          this.getReportList();
        } else {
          this.getDataList();
        }
      } else {
        this.noneMore = true;
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.achievement {
  max-width: 1903px;
  margin: 0 auto;
  &-wrap {
    width: 50%;
    margin: 60px auto 50px auto;
    &-header {
      text-align: center;
    }
    &-list {
      width: 80%;
      margin: 0 auto;
      &-tab {
        color: #999999;
        margin-top: 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &-item {
          position: relative;
          cursor: pointer;
          user-select: none;
          font-size: 18px;
          font-family: "HarmonyOS_Bold";
          &.active, &:hover {
            color: #1e1e1e;
            &:after {
              width: 80%;
              content: " ";
              position: absolute;
              left: 10%;
              bottom: -10px;
              border-bottom: 2px solid #ed6f33;
            }
          }
        }
      }
      &-content {
        margin-top: 50px;
        &-cell {
          color: #999999;
          padding: 30px 0;
          border-top: 1px solid #dadada;
          display: flex;
          flex-direction: column;
          .title {
            color: #1e1e1e;
            font-size: 24px;
            font-family: "HarmonyOS_Bold";
            &:hover,&.active {
              color: #ed6f33;
            }
          }
          .desc {
            margin: 20px 0;
            font-size: 16px;
            line-height: 1.6;
            color: #999999;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .tips {
            font-size: 12px;
            display: flex;
            align-items: center;
            .tag {
              a, span {
                margin-right: 5px;
                display: inline-block;
                &:last-child {
                  margin-right: 0;
                }
              }
              &_link {
                &:hover {
                  color: #ed6f33;
                }
              }
              &:after {
                content: '|';
                margin: 0 10px;
              }
              &:last-child {
                padding: 0;
                &:after {
                  content: "";
                  padding: 0;
                }
              }
            }
          }
          &:last-child {
            border-bottom: 1px solid #dadada;
          }
        }
      }
    }
    &-pagination {
      text-align: center;
      margin: 10% 0 20% 0;
      ::v-deep {
        .el-pagination.is-background .el-pager li:not(.disabled):hover {
          color: #ed6f33;
        }
        .el-pagination.is-background .el-pager li:not(.disabled).active {
          background-color: #ed6f33;
        }
        .el-pagination.is-background .el-pager li:not(.disabled).active:hover {
          color: #ffffff;
        }
      }
    }
    &-page {
      display: none;
    }
  }
}
/* 针对笔记本屏幕或者缩放屏幕 */
@media screen and (min-width: 1024px) and (max-width: 1600px) {
  .achievement {
    &-wrap {
      width: 70%;
    }
  }
}
/* 针对平板 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .achievement {
    &-wrap {
      width: 90%;
    }
  }
}
/* 针对手机 */
@media screen and (max-width: 767px) {
  .achievement {
    &-wrap {
      width: 90%;
      margin-top: 30px;
      &-list {
        width: 100%;
        &-tab {
          margin-top: 30px;
          position: relative;
          overflow: auto hidden;
          &-item {
            padding: 0 10px;
            font-size: 16px;
            line-height: 44px;
            white-space: nowrap;
            &.active, &:hover {
              &:after {
                width: 60%;
                left: 20%;
                bottom: 0px;
              }
            }
          }
          &::-webkit-scrollbar {
            display: none;
          }
        }
        &-content {
          margin-top: 30px;
          &-cell {
            .title {
              font-size: 16px;
            }
            .desc {
              margin-top: 10px;
              font-size: 12px;
            }
          }
        }
      }
      &-pagination {
        display: none;
      }
      &-page {
        margin: 40px auto 30px auto;
        display: table;
        .none-more {
          font-size: 12px;
          padding: 5px 15px;
          border: 1px solid #DCDFE6;
        }
      }
    }
  }
}
</style>