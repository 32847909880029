<template>
  <div class="contactus">
    <web-header active-url="/about" level-url="/contactUs" borderBottom></web-header>
    <div class="contactus-wrap">
      <div class="contactus-wrap-header">
        <div class="contactus-wrap-header-icon">
          <img src="~@/assets/contact_bg.png" />
          <div class="contactus-wrap-header-icon-mask"></div>
        </div>
        <div class="contactus-wrap-header-desc">
          <div class="contactus-wrap-header-desc-title">
            <span>联系我们</span>
          </div>
          <div class="contactus-wrap-header-desc-text">
            <div>中国金融四十人论坛(ChinaFinance40Forum,CF40)成立于2008年4月12日,是目前中国</div>
            <div>极具影响力的金融专业智库平台,专注于经济金融领域的政策研究与交流。</div>
          </div>
        </div>
      </div>
      <div class="contactus-wrap-content">
        <div class="contactus-wrap-content-header">
          <span>联系我们</span>
          <i class="el-icon-arrow-right"></i>
        </div>
        <div class="contactus-wrap-content-box">
          <div class="contactus-wrap-content-box-info">
            <div class="m-left">
              <div class="m-left-cell">
                <div class="m-left-cell-icon">
                  <i class="iconfont icon-location"></i>
                </div>
                <div class="m-left-cell-text">
                  {{ mapInfo.address }}
                </div>
              </div>
              <div class="m-left-cell">
                <div class="m-left-cell-icon">
                  <i class="iconfont icon-phone"></i>
                </div>
                <div class="m-left-cell-text">
                  <span v-for="(item, index) in $store.getters.getContactPhone" :key="index">
                    <a :href="'tel:'+item.value">{{ item.value }}</a>（{{ item.label }}）
                  </span>
                </div>
              </div>
              <div class="m-left-cell">
                <div class="m-left-cell-icon">
                  <i class="iconfont icon-mail"></i>
                </div>
                <div class="m-left-cell-text">
                  <span v-for="(item, index) in $store.getters.getCompanyEmail" :key="index">
                    <a :href="'mailto:'+item.value">{{ item.value }}</a>（{{ item.label }}）
                  </span>
                </div>
              </div>
            </div>
            <div class="m-right">
              <div class="m-right-cell" v-if="mapInfo.official">
                <div class="m-right-cell-icon">
                  <el-image :src="mapInfo.official" :preview-src-list="[mapInfo.official]"></el-image>
                </div>
                <div class="m-right-cell-text">CF40公众号</div>
              </div>
              <div class="m-right-cell" v-if="mapInfo.applet">
                <div class="m-right-cell-icon">
                  <el-image :src="mapInfo.applet" :preview-src-list="[mapInfo.applet]"></el-image>
                </div>
                <div class="m-right-cell-text">CF40小程序</div>
              </div>
            </div>
          </div>
          <baidu-map :center="mapInfo.center" :zoom="mapInfo.zoom" :scroll-wheel-zoom="true" class="contactus-wrap-content-box-map">
            <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
            <bm-marker :position="mapInfo.center" :icon="{url: 'https://api.map.baidu.com/images/marker_red_sprite.png', size: {width: 39, height: 25}}" @click="openInfoWindow">
              <bm-info-window :show="showWindow" :title="mapInfo.websiteName" @close="closeInfoWindow" @open="openInfoWindow">
                <p style="margin-top: 15px">地址：{{ mapInfo.address }}</p>
              </bm-info-window>
            </bm-marker>
          </baidu-map>
          <div class="contactus-wrap-content-box-hire">
            <div class="m-title">
              <span>招聘信息</span>
              <i class="el-icon-arrow-right"></i>
            </div>
            <div class="m-tab">
              <div v-for="(item, index) in tabList" :class="{ 'm-tab-cell': true, 'active': selectTab == item.dict_value }" :key="index" @click="selectTabChange(item.dict_value)">{{ item.dict_label }}</div>
            </div>
            <div class="ml-table" v-loading="loading">
              <div class="ml-table-header">
                <div class="ml-table-header-cell">职位名称</div>
                <div class="ml-table-header-cell">职位类别</div>
                <div class="ml-table-header-cell">工作地点</div>
                <div class="ml-table-header-cell"></div>
              </div>
              <div class="ml-table-content">
                <div v-for="(item, index) in dataList" :class="{ 'ml-table-content-row': true, 'active': selectData == item.id }" :key="index">
                  <div class="ml-table-content-row-line">
                    <div class="ml-table-content-row-line-cell">{{ item.position_name }}</div>
                    <div class="ml-table-content-row-line-cell">{{ item.position_type_id }}</div>
                    <div class="ml-table-content-row-line-cell">{{ item.work_location }}</div>
                    <div class="ml-table-content-row-line-cell">
                      <div class="ml-table-content-row-line-cell-opera" @click="selectDataChange(item.id)">
                        <i :class="selectData == item.id ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
                      </div>
                    </div>
                  </div>
                  <div class="ml-table-content-row-desc" v-html="item.content"></div>
                </div>
                <div class="empty" v-if="!dataList || dataList.length == 0">暂无数据</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <web-footer></web-footer>
    <web-right-menu></web-right-menu>
  </div>
</template>

<script>
import WebHeader from "@/components/webHeader";
import WebFooter from "@/components/webFooter";
import WebRightMenu from "@/components/webRightMenu";
import { dictionaryList, hireList } from "@/api";
export default {
  name: 'ContactUs',
  components: {
    WebHeader,
    WebFooter,
    WebRightMenu
  },
  data() {
    return {
      // 地图中显示窗口
      showWindow: true,
      loading: false,
      // 招聘信息分类
      tabList: [],
      // 选中的分类
      selectTab: 0,
      // 招聘数据
      dataList: [],
      // 选中的招聘数据
      selectData: 0,
    }
  },
  created() {
    this.getHireClass();
  },
  computed: {
    // 地图位置
    mapInfo() {
      let temp = {
        websiteName: "",
        address: "",
        center: {
          lat: 39.943568,
          lng: 116.352808,
        },
        zoom: 19,
        official: "",
        applet: ""
      };
      if (this.$store.getters.getCompanyAddress && this.$store.getters.getCompanyAddress.address) {
        temp.websiteName = this.$store.getters.getWebsiteName;
        temp.address = this.$store.getters.getCompanyAddress.address;
        temp.center.lat = this.$store.getters.getCompanyAddress.lat;
        temp.center.lng = this.$store.getters.getCompanyAddress.lng;
        temp.zoom = this.$store.getters.getCompanyAddress.zoom;
        temp.official = this.$store.getters.getWebSiteQrcodeFooter;
        temp.applet = this.$store.getters.getWechatMiniProgramQrcodeFooter;
      }
      return temp;
    },
  },
  methods: {
    // 获取字典数据-获取招聘信息分类
    async getHireClass() {
      let param = {
        orderBy: "dict_sort",
        sortedBy: "desc",
        search: "dict_type:sys_hire_type|dict_sort:0",
        searchFields: "dict_type:=|dict_sort:<>"
      };
      let res = await dictionaryList(param);
      if (res && res.code == 200) {
        if (res.data && res.data.list && res.data.list.length > 0) {
          this.selectTab = res.data.list[0]['dict_value'];
        }
        this.tabList = res.data.list;
        this.getHireList();
      }
    },
    // 获取招聘信息列表
    async getHireList() {
      this.loading = true;
      let param = {
        pageSize: 50,
        currentPage: 1,
        orderBy: "send_time",
        sortedBy: "desc",
        search: `company_id:${this.selectTab}|status:1`,
        searchFields: "company_id:=|status:="
      }
      let res = await hireList(param);
      if (res && res.code == 200) {
        this.dataList = res.data.list;
      }
      this.loading = false;
    },
    // 选中的类型ID
    selectTabChange(id) {
      if (this.selectTab != id) {
        this.selectTab = id;
        this.getHireList();
      }
    },
    // 选中的信息ID
    selectDataChange(id) {
      if (this.selectData == id) {
        this.selectData = 0;
      } else {
        this.selectData = id;
      }
    },
    // 打开窗口
    openInfoWindow() {
      this.showWindow = true;
    },
    // 关闭窗口
    closeInfoWindow() {
      this.showWindow = false;
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .anchorBL {
    display: none;
  }
}
.contactus {
  max-width: 1903px;
  margin: 0 auto;
  &-wrap {
    position: relative;
    &-header {
      position: relative;
      &-icon {
        position: relative;
        line-height: 0;
        &-mask {
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(86, 114, 165, 0.8);
        }
      }
      &-desc {
        position: absolute;
        top: 20%;
        width: 100%;
        text-align: center;
        color: #ffffff;
        &-title {
          font-size: 32px;
          font-family: "HarmonyOS_Bold";
          span {
            position: relative;
            &:after {
              position: absolute;
              width: 40%;
              left: 30%;
              bottom: -20px;
              content: " ";
              border-bottom: 4px solid #e97a47;
            }
          }
        }
        &-text {
          margin-top: 60px;
          font-size: 22px;
          line-height: 42px;
        }
      }
    }
    &-content {
      position: relative;
      background-color: #ffffff;
      &-header {
        position: absolute;
        width: calc(70% - 30px);
        top: -60px;
        left: 15%;
        padding: 30px 0 0 30px;
        background-color: #ffffff;
        font-size: 22px;
        font-family: "HarmonyOS_Bold";
        display: flex;
        align-items: center;
        i {
          font-size: 14px;
          color: #e97a47;
          margin-left: 10px;
        }
      }
      &-box {
        width: calc(70% - 60px);
        margin: 0 auto;
        padding: 30px 30px 50px 30px;
        &-info {
          display: flex;
          justify-content: space-between;
          .m-left {
            &-cell {
              &-icon {
                color: #ed6f33;
                margin-right: 5px;
                i {
                  font-size: 18px;
                }
              }
              margin-top: 15px;
              display: flex;
              align-items: center;
            }
          }
          .m-right {
            display: flex;
            align-items: center;
            &-cell {
              &-icon {
                max-width: 100px;
              }
              &-text {
                margin-top: 5px;
              }
              margin-right: 30px;
              display: flex;
              flex-direction: column;
              align-items: center;
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
        &-map {
          width: 100%;
          margin: 50px 0;
          height: 300px;
        }
        &-hire {
          .m-title {
            font-size: 22px;
            font-family: "HarmonyOS_Bold";
            i {
              font-size: 14px;
              color: #e97a47;
              margin-left: 10px;
            }
          }
          .m-tab {
            margin: 50px 0 30px 0;
            display: flex;
            justify-content: center;
            &-cell {
              position: relative;
              font-size: 16px;
              font-family: "HarmonyOS_Bold";
              cursor: pointer;
              margin-right: 30px;
              &:last-child {
                margin-right: 0;
              }
              &:hover,&.active {
                &:after {
                  position: absolute;
                  width: 40%;
                  left: 30%;
                  bottom: -10px;
                  content: " ";
                  border-bottom: 2px solid #ed6f33;
                }
              }
            }
          }
          .ml-table {
            width: 100%;
            border: 1px solid #ededed;
            &-header {
              padding: 12px 30px;
              background-color: #f7f7f7;
              display: flex;
              justify-content: space-between;
              align-items: center;
              &-cell {
                font-size: 16px;
                font-family: "HarmonyOS_Bold";
                flex: 1;
                &:last-child {
                  flex: none;
                  min-width: 50px;
                }
              }
            }
            &-content {
              &-row {
                padding: 0 15px;
                &-line {
                  color: #7a7a7a;
                  padding: 12px 15px;
                  font-size: 14px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  border-bottom: 1px solid #f4f4f4;
                  &:last-child {
                    border: none;
                  }
                  &-cell {
                    flex: 1;
                    &:last-child {
                      flex: none;
                      display: flex;
                      justify-content: center;
                      min-width: 50px;
                    }
                    &-opera {
                      width: 20px;
                      height: 20px;
                      cursor: pointer;
                      background-color: #ed6f33;
                      transform: rotate(45deg);
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      i {
                        color: #ffffff;
                        transform: rotate(315deg);
                      }
                    }
                  }
                }
                &-desc {
                  display: none;
                  padding: 30px 15px;
                  background-color: #f7f0ed;
                  ::v-deep {
                    p {
                      font-size: 16px;
                      line-height: 32px;
                      text-align: justify;
                      margin-bottom: 18px;
                    }
                    video, img {
                      max-width: 100% !important;
                    }
                    ol {
                      margin-left: 15px;
                      li {
                        list-style: auto;
                        word-break: break-all;
                      }
                    }
                    ul {
                      margin-left: 18px;
                      li {
                        list-style: disc;
                        word-break: break-all;
                      }
                    }
                    blockquote {
                      margin: 10px 0;
                      padding: 10px;
                      background-color: #f5f2f0;
                      border-left: 8px solid #ed6f33;
                    }
                    code {
                      background-color: #f5f2f0;
                    }
                    hr {
                      margin: 20px;
                      background-color: #ccc;
                      height: 1px;
                      display: block;
                      border: 0;
                    }
                  }
                }
                &.active {
                  .ml-table-content-row-desc {
                    display: block;
                  }
                  .ml-table-content-row-line {
                    color: #ed6f33;
                  }
                }
                &:last-child {
                  &.active {
                    margin-bottom: 15px;
                  }
                }
              }
              .empty {
                padding: 80px 0;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
}
/* 针对笔记本屏幕或者缩放屏幕 */
@media screen and (min-width: 1024px) and (max-width: 1600px) {
  .contactus {
    &-wrap {
      &-content {
        &-header {
          width: calc(80% - 30px);
          left: 10%;
        }
        &-box {
          width: calc(80% - 30px);
        }
      }
    }
  }
}
/* 针对平板 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .contactus {
    &-wrap {
      &-header {
        &-desc {
          top: 10%;
          &-title {
            font-size: 24px;
            span {
              &:after {
                bottom: -10px;
              }
            }
          }
          &-text {
            margin-top: 20px;
            font-size: 16px;
            line-height: 36px;
          }
        }
      }
      &-content {
        &-header {
          width: calc(90% - 30px);
          top: -30px;
          left: 5%;
        }
        &-box {
          width: calc(90% - 30px);
        }
      }
    }
  }
}
/* 针对手机 */
@media screen and (max-width: 767px) {
  .contactus {
    &-wrap {
      &-header {
        &-desc {
          top: 5%;
          &-title {
            font-size: 18px;
            span {
              &:after {
                bottom: -5px;
              }
            }
          }
          &-text {
            margin-top: 10px;
            font-size: 16px;
            line-height: 30px;
          }
        }
      }
      &-content {
        &-header {
          width: calc(100% - 30px);
          top: 0px;
          left: 0;
          padding: 30px 0 0 15px;
        }
        &-box {
          width: calc(100% - 30px);
          padding: 60px 15px 50px 15px;
          &-info {
            flex-direction: column;
            .m-left {
              &-cell {
                span {
                  display: inline-block;
                }
              }
            }
            .m-right {
              margin-top: 15px;
              justify-content: center;
            }
          }
          &-map {
            margin: 30px 0;
          }
          &-hire {
            .m-tab {
              margin: 30px 0 30px 0;
            }
            .ml-table {
              &-header {
                padding: 12px 15px;
                &-cell {
                  &:last-child {
                    min-width: 30px;
                  }
                }
              }
              &-content {
                &-row {
                  &-line {
                    padding: 12px 0;
                    &-cell {
                      &:last-child {
                        display: flex;
                        justify-content: center;
                        max-width: 30px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>