export default {
    data: function () {
        return {
            swiperOption: {
                autoplay: true,//可选选项，自动滑动
                loop: false, // 循环模式选项
                // 如果需要前进后退按钮
                navigation: {
                    nextEl: '.index-swiper-next',
                    prevEl: '.index-swiper-prev',
                }
            },
        }
    },
}