import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
import { configInfo } from "@/api";
export default new Vuex.Store({
    state: {
        // 网站名称
        WebsiteName: "",
        // 网站LOGO
        PcLogo: "",
        // 联系我们二维码和小程序码
        WEB_SITE_QRCODE_FOOTER: "",
        WECHAT_MINI_PROGRAM_QRCODE_FOOTER: "",
        // 公司地址
        CompanyAddress: {},
        // 联系方式
        ContactPhone: [],
        // 企业邮箱
        CompanyEmail: [],
        // 首页标题
        WebSiteTitle: "",
        // 首页关键词
        WebSiteKeywords: "",
        // 首页描述
        WebSiteDesprition: "",
        // 是否开启悼念模式
        MourningMod: false,
        // 网站状态
        WebOpenStatus: true,
    },
    getters: {
        getWebsiteName: (state) => {
            return state.WebsiteName;
        },
        getPcLogo: (state) => {
            return state.PcLogo;
        },
        getWebSiteQrcodeFooter: (state) => {
            return state.WEB_SITE_QRCODE_FOOTER;
        },
        getWechatMiniProgramQrcodeFooter: (state) => {
            return state.WECHAT_MINI_PROGRAM_QRCODE_FOOTER;
        },
        getCompanyAddress: (state) => {
            return state.CompanyAddress;
        },
        getContactPhone: (state) => {
            return state.ContactPhone;
        },
        getCompanyEmail: (state) => {
            return state.CompanyEmail;
        },
        getWebSiteTitle: (state) => {
            return state.WebSiteTitle;
        },
        getWebSiteKeywords: (state) => {
            return state.WebSiteKeywords;
        },
        getWebSiteDesprition: (state) => {
            return state.WebSiteDesprition;
        },
        getMourningMod: (state) => {
            return state.MourningMod;
        },
        getWebOpenStatus: (state) => {
            return state.WebOpenStatus;
        }
    },
    mutations: {
        setWebsiteName: function(state, websiteName) {
            state.WebsiteName = websiteName ? websiteName : "";
        },
        setPcLogo: function(state, PcLogo) {
            state.PcLogo = PcLogo;
        },
        setWebSiteQrcodeFooter: function(state, WEB_SITE_QRCODE_FOOTER) {
            state.WEB_SITE_QRCODE_FOOTER = WEB_SITE_QRCODE_FOOTER;
        },
        setWechatMiniProgramQrcodeFooter: function(state, WECHAT_MINI_PROGRAM_QRCODE_FOOTER) {
            state.WECHAT_MINI_PROGRAM_QRCODE_FOOTER = WECHAT_MINI_PROGRAM_QRCODE_FOOTER;
        },
        setCompanyAddress: function(state, CompanyAddress) {
            state.CompanyAddress = CompanyAddress;
        },
        setContactPhone: function(state, ContactPhone) {
            state.ContactPhone = ContactPhone;
        },
        setCompanyEmail: function(state, CompanyEmail) {
            state.CompanyEmail = CompanyEmail;
        },
        setWebSiteTitle: function(state, WebSiteTitle) {
            state.WebSiteTitle = WebSiteTitle;
        },
        setWebSiteKeywords: function(state, WebSiteKeywords) {
            state.WebSiteKeywords = WebSiteKeywords;
        },
        setWebSiteDesprition: function(state, WebSiteDesprition) {
            state.WebSiteDesprition = WebSiteDesprition;
        },
        setMourningMod: function(state, MourningMod) {
            state.MourningMod = MourningMod;
        },
        setWebOpenStatus: function(state, WebOpenStatus) {
            state.WebOpenStatus = WebOpenStatus;
        },
    },
    actions: {
        // 获取全局配置信息
        async getGlobalConfig({ commit }) {
            let res = await configInfo();
            if (res && res.code == 200 && res.data && res.data.length > 0) {
                for (let i = 0; i < res.data.length; i++) {
                    let item = res.data[i];
                    if (item.config_key == "WEBSITE_TITLE") {
                        if (item.config_value) {
                            commit("setWebSiteTitle", item.config_value);
                        }
                        continue;
                    } else if (item.config_key == "WEBSITE_KEYWORDS") {
                        if (item.config_value) {
                            commit("setWebSiteKeywords", item.config_value);
                        }
                        continue;
                    } else if (item.config_key == "WEBSITE_DESCRIPTION") {
                        if (item.config_value) {
                            commit("setWebSiteDesprition", item.config_value);
                        }
                        continue;
                    } else if (item.config_key == "MOURNING_MOD") {
                        commit("setMourningMod", item.config_value == "0" ? false : true);
                        continue;
                    } else if (item.config_key == "WEB_OPEN_STATUS") {
                        commit("setWebOpenStatus", item.config_value == "1" ? true : false);
                        continue;
                    } else if (item.config_key == "WEBSITE_NAME") {
                        if (item.config_value) {
                            commit("setWebsiteName", item.config_value);
                        }
                        continue;
                    } else if (item.config_key == "PCLOGO") {
                        if (item.config_value) {
                            commit("setPcLogo", JSON.parse(item.config_value).img);
                        }
                        continue;
                    } else if (item.config_key == "COMPANY_ADDRESS") {
                        if (item.config_value) {
                            commit("setCompanyAddress", JSON.parse(item.config_value));
                        }
                        continue;
                    } else if (item.config_key == "CONTACT_PHONE") {
                        if (item.config_value) {
                            commit("setContactPhone", JSON.parse(item.config_value));
                        }
                        continue;
                    } else if (item.config_key == "COMPANY_EMAIL") {
                        if (item.config_value) {
                            commit("setCompanyEmail", JSON.parse(item.config_value));
                        }
                        continue;
                    } else if (item.config_key == "WEB_SITE_QRCODE_FOOTER") {
                        if (item.config_value) {
                            commit("setWebSiteQrcodeFooter", JSON.parse(item.config_value).img);
                        }
                        continue;
                    } else if (item.config_key == "WECHAT_MINI_PROGRAM_QRCODE_FOOTER") {
                        if (item.config_value) {
                            commit("setWechatMiniProgramQrcodeFooter", JSON.parse(item.config_value).img);
                        }
                        continue;
                    }
                }
            }
        },
    },
});