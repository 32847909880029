export default {
    data: function () {
        return {
            // 首页轮播图配置
            oneSwiperOption: {
                autoplay: true,//可选选项，自动滑动
                loop: false, // 循环模式选项
                // 如果需要分页器
                pagination: {
                    el: '.swiper-pagination',
                    clickable :true
                },
            },
            twoSwiperOption: {
                autoplay: true,//可选选项，自动滑动
                loop: false, // 循环模式选项
                // 如果需要前进后退按钮
                navigation: {
                    nextEl: '.index-swiper-next',
                    prevEl: '.index-swiper-prev',
                }
            },
            // 出版物轮播配置
            fiveSwiperOption: {
                grabCursor: true,
                navigation: {
                    nextEl: '.index-five-next',
                    prevEl: '.index-five-prev',
                },
                breakpoints: {
                    320: {  //当屏幕宽度大于等于320
                        slidesPerView: 2,
                        slidesPerGroup: 2,
                        spaceBetween: 10
                    },
                    768: {  //当屏幕宽度大于等于768
                        slidesPerView: 4,
                        slidesPerGroup: 4,
                        spaceBetween: 20
                    },
                    1280: {  //当屏幕宽度大于等于1280
                        slidesPerView: 5,
                        slidesPerGroup: 5,
                        spaceBetween: 30
                    }
                }
            },
            // 理事单位轮播配置
            sixSwiperOption: {
                slidesPerView: 1,
                slidesPerGroup: 1,
                spaceBetween: 50,
                navigation: {
                    nextEl: '.index-six-next',
                    prevEl: '.index-six-prev',
                },
            },
        }
    },
}