import request from '@/utils/request'

/**
 * 获取导航菜单
 * @returns
 */
export async function navigationList (param) {
    return request({
        url: '/api/web/nav',
        method: 'get',
        params: param
    });
}
/**
 * 获取字典数据
 * @returns
 */
export async function dictionaryList (param) {
    return request({
        url: '/api/data/',
        method: 'get',
        params: param
    });
}
/**
 * 获取友情链接
 * @returns
 */
export async function friendLinks (param) {
    return request({
        url: "/api/links/",
        method: 'get',
        params: param
    });
}
/**
 * 获取配置信息
 * @returns
 */
export async function configInfo (param) {
    return request({
        url: '/api/init',
        method: 'get',
        params: param
    });
}
/**
 * 通过分类列表
 * @returns
 */
export async function classifyList (param) {
    return request({
        url: '/api/news/classes',
        method: 'get',
        params: param
    });
}
/**
 * 获取轮播图
 * @returns
 */
export async function slideList (param) {
    return request({
        url: '/api/web/slide',
        method: 'get',
        params: param
    });
}
/**
 * 获取通用数据列表
 * @param modelType  模型ID
 * @param param      检索条件
 * @returns
 */
export async function articleList (modelType,param) {
    return request({
        url: `/api/web/data/${modelType}`,
        method: 'get',
        params: param
    });
}
/**
 * 获取单页详情
 * @returns
 */
export async function singleDetail (id) {
    return request({
        url: '/api/web/singlePageManagement/' + id,
        method: 'get'
    });
}
/**
 * 获取通用详情
 * @returns
 */
export async function commonDetail (modeId, msgId) {
    return request({
        url: `/api/web/data/${modeId}/${msgId}`,
        method: 'get'
    });
}
/**
 * 获取cf40研究报告分类
 * @returns
 */
export async function reportClass (param) {
    return request({
        url: '/api/web/productClass',
        method: 'get',
        params: param
    });
}
/**
 * 获取cf40研究报告列表
 * @returns
 */
export async function reportList (param) {
    return request({
        url: '/api/web/product',
        method: 'get',
        params: param
    });
}
/**
 * 获取招聘信息列表
 * @returns
 */
export async function hireList (param) {
    return request({
        url: '/api/web/recruitmentInformation',
        method: 'get',
        params: param
    });
}
/**
 * 获取组织成员列表
 * @returns
 */
export async function organizaList (param) {
    return request({
        url: '/api/web/organizationMembers',
        method: 'get',
        params: param
    });
}
/**
 * 获取组织成员详情
 * @returns
 */
export async function organizaDetail (msgId) {
    return request({
        url: `/api/web/organizationMembers/${msgId}`,
        method: 'get'
    });
}
/**
 * 获取书籍详情
 * @returns
 */
export async function bookDetail (msgId) {
    return request({
        url: `/api/web/books/${msgId}`,
        method: 'get'
    });
}
/**
 * 通用搜索
 * @returns
 */
export async function generalSearch (words, param) {
    let temp = "/api/web/data/search";
    if (words && words) {
        temp = `${temp}?words=${words}`;
    }
    return request({
        url: temp,
        method: 'get',
        params: param
    });
}
/**
 * 获取下一篇
 * @returns
 */
export async function nextArticle (param) {
    return request({
        url: '/api/web/data/next',
        method: 'post',
        data: param
    });
}