export default {
    data: function () {
        return {
            // 公益项目轮播配置
            swiperOption: {
                grabCursor: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                breakpoints: {
                    320: {  //当屏幕宽度大于等于320
                        slidesPerView: 2,
                        slidesPerGroup: 2,
                        spaceBetween: 10
                    },
                    768: {  //当屏幕宽度大于等于768
                        slidesPerView: 3,
                        slidesPerGroup: 3,
                        spaceBetween: 20
                    },
                    1280: {  //当屏幕宽度大于等于1280
                        slidesPerView: 3,
                        slidesPerGroup: 3,
                        spaceBetween: 30
                    }
                }
            },
        }
    },
}