import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import ZhCnLocal from "element-ui/lib/locale/lang/zh-CN";

import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'genu6xcz8nHNxK6ybQVZXqL7lQ1ajG7u'
});

import 'normalize.css'
import 'swiper/css/swiper.min.css';
import 'font-awesome/css/font-awesome.min.css';

import { loadExternalScript, routerLink, isNumber, setPageSeo, handleTree, createYears } from "@/utils/tool";
// 全局方法挂载
Vue.prototype.loadExternalScript = loadExternalScript;
Vue.prototype.routerLink = routerLink;
Vue.prototype.isNumber = isNumber;
Vue.prototype.setPageSeo = setPageSeo;
Vue.prototype.handleTree = handleTree;
Vue.prototype.createYears = createYears;

Vue.use(ElementUI, {
  size: 'small',
  locale: ZhCnLocal
});

// moment 时间处理
import moment from "moment";

// 全局方法挂载
Vue.prototype.$moment = moment;


Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')


