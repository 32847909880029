<template>
  <div class="member">
    <web-header active-url="/member" borderBottom></web-header>
    <div class="member-wrap">
      <div class="member-wrap-title">成员名录</div>
      <div class="member-wrap-content" v-loading="loading">
        <div class="member-wrap-content-tabs">
          <div v-for="(item, index) in personClass" :class="{ 'member-wrap-content-tabs-cell': true, 'active': selectClass == item.dict_value }" @click="changeClass(item.dict_value)" :key="index">
            <span>{{ item.dict_label }}</span>
            <i :class="selectClass == item.dict_value ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"></i>
          </div>
        </div>
        <div class="member-wrap-content-list">
          <div class="member-wrap-content-list-cell" v-for="(item, index) in dataList" :key="index">
            <a :href="createUrl('/person/'+item.id, item)" target="_blank" class="member-wrap-content-list-cell-img">
              <img :src="item.litpic.img" :alt="item.name" v-if="item.litpic && item.litpic.img" />
              <img src="/images/member_default.png" :alt="item.name" v-else />
            </a>
            <a :href="createUrl('/person/'+item.id, item)" target="_blank" class="member-wrap-content-list-cell-text">
              <div class="name">{{ item.name }}</div>
<!--              <div class="post">{{ item.work_unit }}</div>-->
            </a>
          </div>
        </div>
        <el-empty description="暂无数据" v-if="!dataList || dataList.length == 0"></el-empty>
      </div>
      <div class="member-wrap-pagination" v-if="page.total > page.pageSize">
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="page.current"
            :page-size="page.pageSize"
            layout="prev, pager, next"
            :total="page.total">
        </el-pagination>
      </div>
      <div class="member-wrap-page">
        <el-button type="danger" size="medium" round @click="dataMore" v-if="!noneMore && page.total > page.pageSize">查看更多</el-button>
        <div class="none-more" v-else>没有更多了</div>
      </div>
    </div>
    <web-footer></web-footer>
    <web-right-menu></web-right-menu>
  </div>
</template>

<script>
import WebHeader from "@/components/webHeader";
import WebFooter from "@/components/webFooter";
import WebRightMenu from "@/components/webRightMenu";
import { dictionaryList, organizaList } from "@/api";
export default {
  name: 'Member',
  components: {
    WebHeader,
    WebFooter,
    WebRightMenu
  },
  data() {
    return {
      loading: false,
      // 分类列表
      personClass: [],
      // 分类IDs
      classStr: [],
      // 选择的分类
      selectClass: "",
      // 列表数据
      dataList: [],
      page: {
        // 当前页
        current: 1,
        // 每页显示的条数
        pageSize: 12,
        orderBy: "sort",
        sortedBy: "desc",
        // 总条数
        total: 0
      },
      noneMore: false,
      isMobile: false
    }
  },
  created() {
    this.init();
  },
  mounted() {
    this.checkMobile();
    window.addEventListener('resize', this.checkMobile);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkMobile);
  },
  methods: {
    // 初始化数据
    init() {
      this.getParams();
      this.getDictClass();
    },
    // 判断是否是手机端
    checkMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
    // 获取分类
    async getDictClass() {
      let param = {
        orderBy: "dict_sort",
        sortedBy: "desc",
        search: "dict_type:sys_person_type|dict_sort:0",
        searchFields: "dict_type:=|dict_sort:<>"
      };
      let res = await dictionaryList(param);
      if (res && res.code == 200) {
        this.personClass = res.data.list;
        if (!this.selectClass) {
          this.selectClass = res.data && res.data.list && res.data.list.length > 0 ? res.data.list[0].dict_value : "";
        }
        this.classStr = res.data.list.map(item => item.dict_value);
        this.getDataList();
      }
    },
    // 选择分类
    changeClass(e) {
      this.selectClass = e;
      this.page.current = 1;
      this.dataList = [];
      this.getDataList();
    },
    // 获取参数
    getParams() {
      this.page.current = /^[1-9]\d*$/.test(this.$route.query.page) && Number(this.$route.query.page) > 0 ? Number(this.$route.query.page) : 1;
      this.selectClass = /^[1-9]\d*$/.test(this.$route.query.cid) && Number(this.$route.query.cid) > 0 ? Number(this.$route.query.cid) : "";
    },
    // 修改当前URL参数
    updateUrl() {
      // 获取当前URL主域名
      let url = window.location.origin;
      if (this.selectClass) {
        url = `${url}/member?page=${this.page.current}&cid=${this.selectClass}`;
      } else {
        url = `${url}/member?page=${this.page.current}`;
      }
      history.replaceState(null, null, url);
    },
    // 获取数据
    async getDataList() {
      this.loading = true;
      let param = {
        pageSize: this.page.pageSize,
        currentPage: this.page.current,
        orderBy: this.page.orderBy,
        sortedBy: this.page.sortedBy,
        search: "",
        searchFields: ""
      };
      if (this.selectClass) {
        param.search = `category_id:${this.selectClass}`;
        param.searchFields = "category_id:find";
        param.orderBy = `${this.page.orderBy}_${this.selectClass}`;
      }
      else {
        param.search = `category_id:${this.classStr.join()}`;
        param.searchFields = "category_id:anyfind";
      }
      let res = await organizaList(param);
      if (res && res.code == 200) {
        if (this.isMobile) {
          this.dataList = this.dataList.concat(res.data.list);
        } else {
          this.dataList = res.data.list;
        }
        this.page.total = res.data.total;
      }
      this.updateUrl();
      this.loading = false;
    },
    // 创建URL链接
    createUrl(url, row) {
      if (row && row.jump_link) {
        return row.jump_link;
      }
      return url;
    },
    // 分页，选中的分页
    handleCurrentChange(val) {
      this.page.current = val;
      this.getDataList();
    },
    // 加载更多
    dataMore() {
      if (this.page.total > this.page.current*this.page.pageSize) {
        this.page.current = this.page.current + 1;
        this.getDataList();
      } else {
        this.noneMore = true;
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.member {
  max-width: 1903px;
  margin: 0 auto;
  &-wrap {
    width: 65%;
    margin: 30px auto 50px auto;
    &-title {
      padding: 30px 0;
      font-size: 38px;
      font-family: "HarmonyOS_Bold";
      text-align: center;
    }
    &-content {
      &-tabs {
        margin: 0px 0 50px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        &-cell {
          color: #9f9f9f;
          font-size: 20px;
          font-family: "HarmonyOS_Bold";
          padding-left: 80px;
          span {
            cursor: pointer;
          }
          i {
            cursor: pointer;
            font-size: 16px;
            margin-left: 10px;
            color: #ee763d;
          }
          &:hover, &.active {
            color: #1e1e1e;
          }
        }
      }
      &-list {
        display: flex;
        flex-wrap: wrap;
        &-cell {
          width: 15%;
          margin-right: calc(10% / 5);
          margin-bottom: 60px;
          &-text {
            width: 100%;
            margin-top: 10px;
            display: inline-block;
            .post {
              color: #999999;
            }
          }
          &-img {
            line-height: 0;
            border: 1px solid #F2F2F7;
            display: inline-block;
          }
          &:nth-child(6n) {
            margin-right: 0;
          }
          &:hover {
            color: #ed6f33;
          }
        }
      }
    }
    &-pagination {
      text-align: center;
      margin: 5% 0 10% 0;
      ::v-deep {
        .el-pagination.is-background .el-pager li:not(.disabled):hover {
          color: #ed6f33;
        }
        .el-pagination.is-background .el-pager li:not(.disabled).active {
          background-color: #ed6f33;
        }
        .el-pagination.is-background .el-pager li:not(.disabled).active:hover {
          color: #ffffff;
        }
      }
    }
    &-page {
      display: none;
    }
  }
}
/* 针对平板 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .member {
    &-wrap {
      width: 90%;
      &-title {
        font-size: 30px;
      }
      &-content {
        &-tabs {
          margin: 0px 0 30px 0;
          &-cell {
            font-size: 18px;
          }
        }
        &-list {
          &-cell {
            width: 23%;
            min-width: 23%;
            margin-right: calc(8%/3);
            margin-bottom: 25px;
            &:nth-child(4n) {
              margin-right: 0;
            }
            &:nth-child(6n) {
              margin-right: auto;
            }
          }
        }
      }
    }
  }
}
/* 针对手机 */
@media screen and (max-width: 767px) {
  .member {
    &-wrap {
      width: 90%;
      &-title {
        font-size: 22px;
      }
      &-content {
        &-tabs {
          margin: 0px 0 30px 0;
          &-cell {
            font-size: 16px;
            padding-left: 30px;
            i {
              font-size: 12px;
              margin-left: 3px;
            }
          }
        }
        &-list {
          &-cell {
            width: 30%;
            min-width: 30%;
            margin-right: calc(10%/2);
            margin-bottom: 15px;
            &:nth-child(3n) {
              margin-right: 0;
            }
            &:nth-child(6n) {
              margin-right: auto;
            }
            &-text {
              margin-top: 5px;
              font-size: 12px;
            }
          }
        }
      }
      &-pagination {
        display: none;
      }
      &-page {
        margin: 40px auto 30px auto;
        display: table;
        .none-more {
          font-size: 12px;
          padding: 5px 15px;
          border: 1px solid #DCDFE6;
        }
      }
    }
  }
}
</style>