<template>
  <div class="exchange">
    <web-header active-url="/exchange" borderBottom></web-header>
    <div class="exchange-wrap">
      <div class="exchange-wrap-header" v-if="bannerImage">
        <a href="http://www.bundsummit.org/" target="_blank">
          <img :src="bannerImage" />
        </a>
      </div>
      <div class="exchange-wrap-list" v-loading="loading">
        <div class="exchange-wrap-list-title">更多国际交流</div>
        <div class="exchange-wrap-list-cell" v-for="(item, index) in dataList" :key="index">
          <div class="exchange-wrap-list-cell-left">
            <a :href="createUrl('/article/1/'+item.id, item)" target="_blank" class="title">{{ item.title }}</a>
            <div class="desc">
              {{ removeHtmlTags(item.introduction) }}
            </div>
            <div class="tips">
              <div class="tag">{{ $moment(item.send_time).format("YYYY-MM-DD") }}</div>
              <div class="tag" v-if="item.organization_member_urls && item.organization_member_urls.length > 0">
                <template v-for="(jtem, jndex) in item.organization_member_urls">
                  <a :href="'/person/'+jtem.id" target="_blank" class="tag_link" :key="jndex" v-if="jtem.id">
                    {{ jtem.name }}
                  </a>
                  <span :key="jndex" v-else>
                  {{ jtem.name }}
                </span>
                </template>
              </div>
              <template v-else>
                <div class="tag" v-if="item.author">{{ item.author }}</div>
              </template>
            </div>
          </div>
          <a :href="createUrl('/article/1/'+item.id, item)" target="_blank" class="exchange-wrap-list-cell-right" v-if="item.cover_image && item.cover_image.img">
            <img :src="item.cover_image.img" />
          </a>
        </div>
        <el-empty description="暂无数据" v-if="!dataList || dataList.length == 0"></el-empty>
      </div>
      <div class="exchange-wrap-pagination" v-if="page.total > page.pageSize">
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="page.current"
            :page-size="page.pageSize"
            layout="prev, pager, next"
            :total="page.total">
        </el-pagination>
      </div>
      <div class="exchange-wrap-page">
        <el-button type="danger" size="medium" round @click="dataMore" v-if="!noneMore && page.total > page.pageSize">查看更多</el-button>
        <div class="none-more" v-else>没有更多了</div>
      </div>
    </div>
    <web-footer></web-footer>
    <web-right-menu></web-right-menu>
  </div>
</template>

<script>
import WebHeader from "@/components/webHeader";
import WebFooter from "@/components/webFooter";
import WebRightMenu from "@/components/webRightMenu";
import { articleList, navigationList } from "@/api";
export default {
  name: 'Exchange',
  components: {
    WebHeader,
    WebFooter,
    WebRightMenu
  },
  data () {
    return {
      // banner图
      bannerImage: "",
      // 加载状态
      loading: false,
      // 列表数据
      dataList: [],
      page: {
        // 当前页
        current: 1,
        // 每页显示的条数
        pageSize: 10,
        orderBy: "send_time",
        sortedBy: "desc",
        // 总条数
        total: 0
      },
      noneMore: false,
      isMobile: false
    }
  },
  created() {
    this.init();
  },
  mounted() {
    this.checkMobile();
    window.addEventListener('resize', this.checkMobile);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkMobile);
  },
  methods: {
    // 初始化数据
    init() {
      this.getExchangeBanner();
      this.getParams();
      this.getDataList();
    },
    // 判断是否是手机端
    checkMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
    // 创建URL链接
    createUrl(url, row) {
      if (row && row.jump_link) {
        return row.jump_link;
      }
      return url;
    },
    // 获取国际交流banner
    async getExchangeBanner() {
      let param = {
        pageSize: 1,
        currentPage: 1,
        orderBy: "",
        sortedBy: "",
        search: "id:24",
        searchFields: "id:="
      };
      let res = await navigationList(param);
      if (res && res.code == 200) {
        if (res.data && res.data.list && res.data.list.length > 0) {
          this.bannerImage = res.data.list[0].banner && res.data.list[0].banner.img ? res.data.list[0].banner.img : "";
        }
      }
    },
    // 获取参数
    getParams() {
      this.page.current = /^[1-9]\d*$/.test(this.$route.query.page) && Number(this.$route.query.page) > 0 ? Number(this.$route.query.page) : 1;
    },
    // 修改当前URL参数
    updateUrl() {
      // 获取当前URL主域名
      let url = window.location.origin;
      url = `${url}/exchange?page=${this.page.current}`;
      history.replaceState(null, null, url);
    },
    // 获取数据
    async getDataList() {
      this.loading = true;
      let param = {
        pageSize: this.page.pageSize,
        currentPage: this.page.current,
        orderBy: this.page.orderBy,
        sortedBy: this.page.sortedBy,
        search: `class_id:15`,
        searchFields: "class_id:="
      };
      let res = await articleList(1, param);
      if (res && res.code == 200) {
        if (this.isMobile) {
          this.dataList = this.dataList.concat(res.data.list);
        } else {
          this.dataList = res.data.list;
        }
        this.page.total = res.data.total;
      }
      this.updateUrl();
      this.loading = false;
    },
    // 分页，选中的分页
    handleCurrentChange(val) {
      this.page.current = val;
      this.getDataList();
    },
    // 加载更多
    dataMore() {
      if (this.page.total > this.page.current*this.page.pageSize) {
        this.page.current = this.page.current + 1;
        this.getDataList();
      } else {
        this.noneMore = true;
      }
    },
    // 去除HTML标签
    removeHtmlTags(value) {
      return value ? value.replace(/<[^>]+>/g, "").replace(/&nbsp;/g, '').replace(/^\s+|\s+$/g, '') : "";;
    },
  }
}
</script>

<style lang="scss" scoped>
.exchange {
  max-width: 1903px;
  margin: 0 auto;
  &-wrap {
    width: 50%;
    margin: 60px auto 50px auto;
    &-header {
      line-height: 0;
      text-align: center;
      a {
        display: inline-block;
      }
    }
    &-list {
      width: 90%;
      margin: 80px auto 30px auto;
      &-title {
        font-size: 14px;
        color: #999999;
        margin-bottom: 30px;
      }
      &-cell {
        color: #999999;
        padding: 30px 0;
        border-top: 1px solid #dadada;
        display: flex;
        &-left {
          padding-right: 30px;
          flex: 1;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          .title {
            color: #1e1e1e;
            font-size: 24px;
            font-family: "HarmonyOS_Bold";
            &:hover {
              color: #ed6f33;
            }
          }
          .desc {
            margin: 20px 0;
            font-size: 16px;
            line-height: 1.6;
            color: #999999;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .tips {
            font-size: 12px;
            display: flex;
            align-items: center;
            .tag {
              padding-right: 10px;
              a, span {
                margin-right: 5px;
                display: inline-block;
                &:last-child {
                  margin-right: 0;
                }
              }
              &:after {
                content: '|';
                padding-left: 10px;
              }
              &:last-child {
                padding: 0;
                &:after {
                  content: "";
                }
              }
            }
          }
        }
        &-right {
          max-width: 220px;
          line-height: 0;
        }
        &:last-child {
          border-bottom: 1px solid #dadada;
        }
      }
    }
    &-pagination {
      text-align: center;
      margin: 10% 0 20% 0;
      ::v-deep {
        .el-pagination.is-background .el-pager li:not(.disabled):hover {
          color: #ed6f33;
        }
        .el-pagination.is-background .el-pager li:not(.disabled).active {
          background-color: #ed6f33;
        }
        .el-pagination.is-background .el-pager li:not(.disabled).active:hover {
          color: #ffffff;
        }
      }
    }
    &-page {
      display: none;
    }
  }
}
/* 针对笔记本屏幕或者缩放屏幕 */
@media screen and (min-width: 1024px) and (max-width: 1600px) {
  .exchange {
    &-wrap {
      width: 70%;
    }
  }
}
/* 针对平板 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .exchange {
    &-wrap {
      width: 90%;
      &-header {

      }
    }
  }
}
/* 针对手机 */
@media screen and (max-width: 767px) {
  .exchange {
    &-wrap {
      width: 90%;
      margin-top: 30px;
      &-list {
        width: 100%;
        margin-top: 30px;
        &-cell {
          padding: 15px 0;
          flex-direction: column-reverse;
          &-left {
            margin-top: 10px;
            padding-right: 0;
            .title {
              font-size: 16px;
            }
            .desc {
              margin-top: 10px;
              font-size: 12px;
            }
            .tips {
              font-size: 12px;
              margin-top: 10px;
            }
          }
          &-right {
            max-width: 100%;
          }
        }
      }
      &-pagination {
        display: none;
      }
      &-page {
        margin: 40px auto 30px auto;
        display: table;
        .none-more {
          font-size: 12px;
          padding: 5px 15px;
          border: 1px solid #DCDFE6;
        }
      }
    }
  }
}
</style>