<template>
  <div class="singles" v-loading="loading">
    <web-header borderBottom></web-header>
    <div class="singles-title">
      <span>{{ info.title }}</span>
    </div>
    <div class="singles-content" v-html="info.content"></div>
    <web-footer></web-footer>
    <web-right-menu></web-right-menu>
  </div>
</template>

<script>
import WebHeader from "@/components/webHeader";
import WebFooter from "@/components/webFooter";
import WebRightMenu from "@/components/webRightMenu";
import { singleDetail } from "@/api";
export default {
  name: 'Singles',
  components: {
    WebHeader,
    WebFooter,
    WebRightMenu
  },
  data() {
    return {
      loading: false,
      info: {}
    }
  },
  created() {
    if (this.$route.params.id && this.isNumber(this.$route.params.id)) {
      this.getDataDetail(this.$route.params.id);
    }
  },
  methods: {
    // 获取获取详情
    async getDataDetail(id) {
      this.loading = true;
      let res = await singleDetail(id);
      if (res && res.code == 200) {
        this.info = res.data;
        if (this.info.jump_link) {
          this.routerLink(this.info.jump_link, false);
        }
        if (this.info.seo_title) {
          this.setPageSeo(`${this.info.seo_title}_${this.$store.getters.getWebSiteTitle}`,this.info.keywords,this.info.seo_desc);
        } else {
          this.setPageSeo(`${this.info.title}_${this.$store.getters.getWebSiteTitle}`,this.info.keywords,this.info.seo_desc);
        }
      }
      this.loading = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.singles {
  max-width: 1903px;
  margin: 0 auto;
  &-title {
    margin: 80px 0 50px 0;
    text-align: center;
    font-size: 36px;
    font-family: "HarmonyOS_Bold";
    span {
      position: relative;
      &:after {
        position: absolute;
        left: 10%;
        bottom: -10px;
        content: '';
        display: inline-block;
        width: 80%;
        height: 3px;
        background-color: #ed6f33;
      }
    }
  }
  &-content {
    width: 50%;
    min-height: 500px;
    margin: 30px auto 100px auto;
    ::v-deep {
      p {
        font-size: 16px;
        display: block;
        line-height: 32px;
        margin-bottom: 18px;
      }
      video, img {
        max-width: 100% !important;
      }
      ol {
        margin-left: 15px;
        li {
          list-style: auto;
          word-break: break-all;
        }
      }
      ul {
        margin-left: 18px;
        li {
          list-style: disc;
          word-break: break-all;
        }
      }
      blockquote {
        margin: 10px 0;
        padding: 10px;
        background-color: #f5f2f0;
        border-left: 8px solid #ed6f33;
      }
      code {
        background-color: #f5f2f0;
      }
      hr {
        margin: 20px;
        background-color: #ccc;
        height: 1px;
        display: block;
        border: 0;
      }
    }
  }
}
/* 针对平板 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .singles {
    &-title {
      margin: 50px 0 20px 0;
      font-size: 22px;
    }
    &-content {
      width: 80%;
      margin: 50px auto 70px auto;
    }
  }
}
/* 针对手机 */
@media screen and (max-width: 767px) {
  .singles {
    &-title {
      margin: 50px 0 20px 0;
      font-size: 22px;
    }
    &-content {
      width: calc(100% - 60px);
      padding: 0 30px;
      margin: 50px auto 70px auto;
    }
  }
}
</style>